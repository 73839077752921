<template >
  <div>
    <v-system-bar clipped-left height="70px" app>
      <div>
        <v-toolbar-items class="img-bc sub-color-bc">
          <v-img
            aspect-ratio="1"
            src="https://time-repair.jp/ohmiya/imgs/S__12410889.png"
          ></v-img>
        </v-toolbar-items>
      </div>
      <div>
        <v-toolbar-title class="app-title sub-color-bc">
          <div>
            <router-link to="/" class="deco"
              ><span class="sub-title">時計修理専門 -TimeRepair-</span><br />
              <span class="titles">タイムリペア</span></router-link
            >
          </div>
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn
              class="btn mr-5"
              v-show="
                $vuetify.breakpoint.xs ||
                $vuetify.breakpoint.sm ||
                $vuetify.breakpoint.md
              "
              v-on="on"
              text
              >メニュー<v-icon>mdi-menu-down</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item
              v-for="support in supports"
              :key="support.name"
              @click="to_hash(support.root, support.hash)"
            >
              <v-list-item-icon
                ><v-icon>{{ support.icon }}</v-icon></v-list-item-icon
              >
              <v-list-item-content
                ><v-list-item-title>{{
                  support.name
                }}</v-list-item-title></v-list-item-content
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-row
        justify="center"
        v-show="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg"
      >
        <v-btn height="50px" text class="menu-btn" @click="to_hash('Home')"
          >TOP<br />ホーム</v-btn
        >
        <v-btn
          height="50px"
          text
          class="menu-btn"
          @click="to_hash('price', '#PRICETOP')"
          >PRICE<br />料金表</v-btn
        >
        <v-btn
          height="50px"
          text
          class="menu-btn"
          @click="to_hash('timeRepairOnline')"
          >ONLINE<br />オンライン</v-btn
        >
        <v-btn
          height="50px"
          text
          class="menu-btn"
          @click="to_hash('repairCenter')"
          >atelier<br />工房について</v-btn
        >
        <v-btn height="50px" text class="menu-btn" @click="to_hash('QandA')"
          >Q＆A<br />よくある質問</v-btn
        >
        <v-btn
          height="50px"
          text
          class="menu-btn"
          @click="to_hash('news', '#NEWSTOP')"
          >NEWS<br />お知らせ</v-btn
        >
        <v-btn height="50px" text class="menu-btn" @click="to_hash('Stores')"
          >STORE<br />店舗情報</v-btn
        >
        <v-btn height="50px" text class="menu-btn" to="contact"
          >CONTACT<br />問い合わせ</v-btn
        >
      </v-row>
    </v-system-bar>

    <v-dialog v-model="this.$store.state.isProgress" persistent>
      <progress-rabbit></progress-rabbit>
    </v-dialog>
  </div>
</template>
    
<script>
import progressRabbit from "@/components/organisms/progress_r";
export default {
  name: "sample2",
  components: {
    progressRabbit,
  },

  data() {
    return {
      message: "",
      item_l: true,
      item_r: true,
      drawer: null,
      supports: [
        { name: "Top", icon: "", root: "Home", hash: "#HOME" },
        { name: "料金表", icon: "", root: "price" },
        { name: "店舗情報", icon: "", root: "Stores" },
        { name: "オンライン", icon: "", root: "timeRepairOnline" },
        { name: "工房について", icon: "", root: "repairCenter" },
        { name: "NEWS", icon: "", root: "news", hash: "#NEWSTOP" },
        { name: "Q&A", icon: "", root: "QandA" },
        { name: "問い合わせ", icon: "", root: "contact" },
      ],
    };
  },
  methods: {
    to_hash(path, Hash) {
      if (path != this.$route.name) {
        this.$router.push({ name: path, hash: Hash });
      }
    },
  },
};
</script>
<style scoped>
.deco {
  text-decoration: none;
}
.sub-title {
  color: #ffffff;
  font-family: "georgia";
  text-align: left;
  font-size: 11px;
  text-align: center;
  margin-left: 15px;
}
.titles {
  color: #ffffff;
  font-family: "georgia";
  font-size: 18px;
  margin-left: 15px;
  margin-top: -10px;
}

.img-bc {
  color: #ffffff;
  text-align: center;
  height: 70px;
  width: 80px;
  padding-right: 10px;
  margin-bottom: 0px;
  padding-left: 20px;
  margin-left: -30px;
  transform: skewX(-16deg);
}
.app-title {
  color: #ffffff;

  width: 200px;
  height: 70px;
  margin-left: -2px;
  transform: skewX(-16deg);
}
.tel {
  color: black;
  font-size: 28px;
  font-family: "georgia";
}
.font-16 {
  font-size: 16px;
}
.menu-btn {
  transition: all 0.4s;
  transform: skewX(-16deg);
}
.menu-btn:hover {
  background-color: #d28a94;
  transform: skewX(-16deg);
}
</style>