<template>
  <v-container>
    <v-row  no-gutters>
      <v-col v-for="(image,i) in upimages" :key="i" cols="12" sm="6" md="4" lg="4" xl="4">
      <v-card>
       <v-img :src="image.url" aspect-ratio="1" :contain="true"></v-img>
         <v-btn color="primary" @click="deleteItem(i)">画像を変える</v-btn> 
      </v-card>
      </v-col>
      </v-row>
      <v-row v-if="isUploading" class="loader">
        <v-col cols="12">        
        <div>Now loading...</div>
      </v-col>    
      </v-row>  
     
      <v-row>
        <v-col cols="12" v-if="!isUploading">
      <v-file-input
        v-if="indexs<6"
        accept="image/*"
        prepend-icon="mdi-camera"
       @change="selectedFile"
       clear-icon=""
       hint="6枚まで入力可能です。"
       prefix="写真を登録する。"
     ></v-file-input>
     <span class="fontred">1ファイル１０MB以上いないにしてください。<br>一部android機種ではカメラが起動いたしません。<br>お手数ですが事前にカメラ機能で写真をご用意ください。</span>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
    <div v-if="indexs>5">最大6枚までになります。</div>
    <div>{{errMessage}}</div>
    </v-row>
  </v-container>
  
</template>

<script>
import ImageUtil from "@/plugins/imageUtil";
export default {
  data() {
    return {
      isUploading : false,
      indexs:0,
      errMessage:"",
      upimage: { fileUrl: "", fileName: "", blob: null },
      fileInfo: {
        before: { size: 0 },
        after: { size: 0 },
     },
     upimages:[]
 
    };
  },
  props: ['clear'],
  methods: {
    async selectedFile(fileInfo) {
      this.isUploading = true;
      this.errMessage =""
      const file = fileInfo;
      if (!file) {
        this.isUploading = false;
        return;
      }
      try {
        // 圧縮した画像を取得
        const compFile = await ImageUtil.getCompressImageFileAsync(file);
        //ファイルサイズの表示
        this.fileInfo.before.size = (file.size / 1024 / 1024).toFixed(4);
        this.fileInfo.after.size = (compFile.size / 1024 / 1024).toFixed(4);
        // 画像情報の設定
        this.upimage.blob = compFile;
        this.upimage.fileUrl = await ImageUtil.getDataUrlFromFile(compFile);
        this.upimage.fileName = file.name;
        this.upimages.push ({blob:this.upimage.blob,fileName:this.upimage.fileName,url:this.upimage.fileUrl })
        this.isImages=true
      } catch (err) {
        this.errMessage ="ファイルがアップロードできません。"

      } finally {
        
        this.indexs++;  
        this.isUploading = false;
      }
    },
     deleteItem(index){
      this.indexs--
      this.upimages.splice(index, 1);
      },

  },
  watch:{
    upimages: function(){
       this.$emit("get-images", this.upimages,this.isImages);
    },
    isUploading: function(){
       this.$emit("get-is-images", this.isUploading);
    },
    clear:function(){
       if(this.clear==true){
         this.upimages=[]
         this.indexs=0
       }
    }
  }
};
</script>

<style>
.fontred{
font-size:8pt;
color: rgb(255, 0, 0);

}
</style>