<template>
  <div class="mt-10">
    <div class="top-box" ref="TOP">
      <div class="slide">
        <img src="/imgs/img1.jpg" alt="" />
        <img src="/imgs/P1011546.jpg" alt="" />
        <img src="/imgs/img3.jpg" alt="" />
        <img src="/imgs/P1011595.jpg" alt="" />
      </div>
      <p class="top-left">
        時を取り戻す<br />
        <span class="top-left-under">TimeRepair</span>
      </p>
    </div>

    <div class="top-text-box">
      <h2>タイムリペアとは</h2>
      <div class="text-sub-title mt-2">
        タイムリペアは、都内大手デパートの「時計修理」5店舗を運営する時計修理会社の直営店です。
      </div>
      <div class="top-text mt-2 mx-auto">
        「街の時計屋さんが無くて修理をどこに持って行っていいかわからない」<br />
        そんな、声を多くいただきます。<br />
        そこで、弊社では一人でも多くのお客様の困ったを解決できるよう、直営の専門店をオープンいたしました。<br />
        デパート品質を低価格でご提供いたします。<br />
        時計修理専門の技術者が直接お客様の相談や電池交換、修理をお受けしております。<br />
        ご相談は無料ですのでお気軽にご相談ください。<br />
        店舗にご来店が難しい方はオンラインでの受付もしております。<br />
      </div>
    </div>
    <v-row justify="center" class="mt-8 pb-5">
      <events></events>
    </v-row>

    <div>
      <v-row justify="center" class="pb-5" style="background-color: #ede8ef"
        ><work-s></work-s
      ></v-row>
    </div>
    <div>
      <v-row class="mt-5 mb-10">
        <store></store>
      </v-row>
    </div>
    <div>
      <v-row justify="center" class="pb-5"><brand-s></brand-s></v-row>
    </div>
    <v-card class="mb-10" flat id="QA">
      <qand-a v-bind:propsItems="{ is_insert: 0 }"></qand-a>
    </v-card>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
import workS from "@/components/pages/works";
import brandS from "@/components/pages/brands";
import qandA from "@/components/pages/QandA";
import Events from "@/components/pages/Events";
import store from "@/components/template/insertStore";
export default {
  data() {
    return {
      notice: [],
    };
  },
  mixins: [doAxios],
  components: {
    //  numberCount,
    qandA,
    workS,
    store,
    brandS,
    Events,
  },
  methods: {},
  mounted() {
    if (this.$route.hash.match(/^#/)) {
      const el = this.$refs[this.$route.hash.slice(1)];
      el.scrollIntoView({ behavior: "smooth" });
    } else {
      const el = this.$refs["TOP"];
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
  watch: {},
};
</script>
<style scoped>
/*枠の大きさ*/
.top-box {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  background-color: rgba(19, 53, 128, 0.8);
}
.top-text-box {
  background-color: #ffffff; /* 背景色指定 */
  position: relative;
  width: 80%; /* 幅指定 */

  margin: -50px auto 0; /* 中央寄せ */
  border-radius: 20px;
  padding-bottom: 20px;
  text-align: center;
  box-shadow: 0 10px 25px 0 rgba(19, 53, 128, 0.3);
}
.top-text-box h2 {
  padding-top: 20px;
  font-family: honoka, serif;
  font-size: 2rem;
}
.top-text-box .text-sub-title {
  color: #4a55e9;
  font-size: 1.2rem;
  font-family: "NotoSerifCJKjp", serif;
  width: 80%;
  margin: -50px auto 0; /* 中央寄せ */
}

.store-box {
  margin-top: 10px;
  border: 2px solid #a5964b; /* 境界線を実線で指定 */
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(19, 53, 128, 0.2);
  width: 90%;
  min-height: 300px;
  padding: 1%;
  overflow: hidden;
}
.store-box-under {
  margin-top: 10px;
  border: 2px solid #ff4863; /* 境界線を実線で指定 */
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(19, 53, 128, 0.2);
  width: 95%;
  min-height: 100px;
  padding: 1%;
  overflow: hidden;
}
.online-title {
  font-weight: 700;
  font-size: 1.8rem;
}
.online-sub-title {
  color: #ff4863;
  font-size: 1.3rem;
}
/*元画像の大きさ*/
.top-text {
  color: #333;
  font-size: 0.9rem;
  font-weight: normal;
  line-height: 1.8rem;
  letter-spacing: 0.15em;
  text-align: left;
  padding-left: 30px;
}

.top-left {
  position: absolute;
  top: 20%;
  left: 10%;
  color: white;
  font-size: 3rem;
  font-family: honoka, serif;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: 0.14em;
  transform: scaleX(0.825);
  transform-origin: left center;
}
.top-left-under {
  position: absolute;

  font-size: 25px;
  color: white;
}
.slide {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
}

.slide img {
  position: absolute;
  top: 20%;
  left: 50%;

  width: calc(100% + 100px);
  opacity: 0;
  animation: slideshow 32s linear infinite;
}

.slide img:nth-child(2) {
  animation-delay: 8s;
}

.slide img:last-child {
  animation-delay: 16s;
}
@keyframes slideshow {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  10% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  38% {
    opacity: 0;
  }
  80% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
}
@keyframes fadeIn {
  from {
  }

  to {
  }
}
</style>
