<template>
  <div class="mt-10">
    <div ref="TOP"></div>

    <div class="top-box mt-10">
      <div class="slide">
        <img src="/imgs/center/IMG_0012.jpg" alt="" />
      </div>
      <p class="top-left">RepairCenter<br /></p>
    </div>
    <v-row justify="center">
      <v-card width="90%" class="mt-5" flat>
        <v-row justify="center">
          <v-col cols="10" class="text-center">
            <div class="animation_box mt-8 section-title">
              <h2>
                <b style="color: #004d99">―</b>工房について<b
                  style="color: #004d99"
                  >―</b
                >
              </h2>
              <p>
                時計修理技能士1級を所持した技術者が、<br />
                お客様の時計を入念に分解・洗浄・注油・組立・調整を行っております。
              </p>
            </div>
          </v-col>
          <v-col cols="10">
            <img src="imgs/center/koubou.jpg" width="90%" alt="工房" />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
            <div class="machinery">
              <figure class="side_image">
                <img
                  src="imgs/center/ma01.jpg"
                  alt="ウォッチ洗浄機"
                  height="150px"
                  class="pl-5 pr-5"
                />
              </figure>
              <p class="machinerytext">
                <b>ウォッチ洗浄機（ヴェルボETC V）</b> <br />
                超音波と回転による洗浄で、長年蓄積した機械の汚れを洗い流して綺麗にする時計用の自動洗浄機です。
              </p>
            </div>
          </v-col>
          <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
            <div class="machinery">
              <figure class="side_image">
                <img
                  class="pl-5 pr-5"
                  src="imgs/center/ma02.jpg"
                  alt="ワインディングマシーン"
                  height="150px"
                />
              </figure>
              <p class="machinerytext">
                <b>ワインディングマシーン</b> <br />
                観覧車のように回転しながら、様々な姿勢での自動巻き上げや精度を、実際の使用条件に近い形でテストする為の機械です。
              </p>
            </div>
          </v-col>
          <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
            <div class="machinery">
              <figure class="side_image">
                <img
                  class="pl-5 pr-5"
                  src="imgs/center/ma03.jpg"
                  alt="防水試験器"
                  height="150px"
                />
              </figure>
              <p class="machinerytext">
                <b
                  >防水試験器<br />
                  (ハムロン・テック WPC8110)</b
                >
                <br />
                この機械の中で、時計の周囲に空気による圧力を加えると時計ケース内外の圧力差により、表面にわずかにゆがみが生じます。このゆがみ具合を測定することで、防水の試験を行います。
              </p>
            </div>
          </v-col>
          <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
            <div class="machinery">
              <figure class="side_image">
                <img
                  class="pl-5 pr-5"
                  src="imgs/center/ma04.jpg"
                  alt="バフ"
                  height="150px"
                />
              </figure>
              <p class="machinerytext">
                <b>バフ（ハープNO.9400）</b> <br />
                柔らかい布やフェルトでできたホイール状のバフで、パーツ等を研磨するための機械です。用途に合わせて数種類を使い分けます。
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <v-card class="mt-5 mb-10" flat id="QA">
      <v-row justify="center">
        <div class="event-title"><h2>よくある質問</h2></div>
      </v-row>
      <qand-a></qand-a>
    </v-card>

    <div class="mb-10"></div>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

import qandA from "@/components/pages/QandA";

export default {
  data() {
    return {};
  },
  mixins: [doAxios],
  components: {
    //  numberCount,
    qandA,
  },
  methods: {},
  mounted() {
    if (this.$route.hash.match(/^#/)) {
      const el = this.$refs[this.$route.hash.slice(1)];
      el.scrollIntoView({ behavior: "smooth" });
    } else {
      const el = this.$refs["TOP"];
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
  watch: {},
};
</script>
<style scoped>
/*枠の大きさ*/
.top-box {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  background-color: rgba(19, 53, 128, 0.8);
}
.second-box-title {
  border: 3px solid;
  border-left-color: rgb(9, 85, 2);
  border-top: none;
  border-bottom: none;
  border-right: none;
}
.price-container {
  padding-top: 40px;
}
.price {
  text-align: left;
  font-size: 13px;
  letter-spacing: 2px;
}

.price-title {
  text-align: left;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.font-12 {
  font-size: 12px;
}
.boder3 {
  border-bottom: 3px solid orange;
}
.boder1 {
  border-bottom: 1px solid orange;
}
.price th {
  background-color: cadetblue;
  color: white;
  font-family: sans-serif;
}
.price td {
  border-bottom: solid 1px #dddddd;
  font-family: sans-serif;
}

.price tr:hover {
  background-color: #ffcc99; /* マウスオーバー時の行の背景色 */
}
.price td:hover {
  background-color: #ccffff; /* マウスオーバー時のセルの背景色 */
}
.store-box {
  margin-top: 10px;
  border: 2px solid #a5964b; /* 境界線を実線で指定 */
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(19, 53, 128, 0.2);
  min-height: 200px;
  padding: 1%;
  overflow: hidden;
}

.top-left {
  position: absolute;
  top: 20%;
  left: 10%;
  color: white;
  font-size: 2.5rem;

  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.14em;
  transform: scaleX(0.825);
  transform-origin: left center;
  text-shadow: 3px 4px 3px rgb(0, 89, 255);
}

.slide {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  background: white;
}

.slide img {
  position: absolute;
  top: 20%;
  left: 50%;

  width: calc(100% + 100px);
  opacity: 0;
  animation: slideshow 8s both 1;
}
.event-title {
  width: 80%;
  color: darkblue;
  background: linear-gradient(to right, pink, #ffe8ff);
  margin: 15px auto 0;
}
.machinery {
  padding: 10px 0px;
  margin: 30px 20px 0px 20px;
  border-bottom: 1px dashed #939393;
  border-top: 1px dashed #939393;
  min-height: 200px;
}
.machinerytext {
  display: inline-block;
  min-width: 200px;
  max-width: 350px;
}
figure.side_image {
  float: left;
}
.section-title-header p.wow {
  font-size: 17px;
  animation: slideshow 8s both 1;
}

div.animation_box {
  padding: 10px;
  animation: fadeIn 5s ease 1s 1 normal backwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideshow {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  10% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -70%);
  }
}
</style>
