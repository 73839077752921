<template>


  <div >
    <Home/>
  </div>


</template>

<script>
// @ is an alias to /src
import Home from '../components/pages/Home.vue'

export default {
 // name: 'userLogin',
  components: {
    Home
  }
}
</script>
