import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store/index.js";
import Home from "@/views/Home";
//import numberCall from "@/components/pages/numberCall";
//import numberCallView from "@/components/pages/numberCallView";
//import StaffLogin from "@/components/pages/StaffLogin";
import price from "@/components/pages/price";
import news from "@/components/pages/News";
//mport inputCustomer from "@/components/template/inputCustomer";
//import entryForm from "@/components/pages/EntryForm";
//import statusView from "@/components/pages/StatusView";
//import InquiryForm from "@/components/pages/InquiryForm";
//import test from "@/components/pages/test";
import ohmiyaten from "@/components/pages/ohmiya";
import repairCenter from "@/components/pages/repairCenter";
import timeRepairOnline from "@/components/pages/timeRepairOnline";
import QandA from "@/components/pages/QandA";
import Stores from "@/components/pages/Stores";
import inputAchievement from "@/components/pages/inputAchievement";
import contact from "@/components/pages/contact";
//もろもろ
import contract from "@/components/pages/contract/contract";
import privacy from "@/components/pages/contract/privacy";
import terms from "@/components/pages/contract/terms";

//ブランド
import rolex from "@/components/pages/brands/rolex";
import omega from "@/components/pages/brands/omega";
import longines from "@/components/pages/brands/longines";
import hermes from "@/components/pages/brands/hermes";
import cartier from "@/components/pages/brands/cartier";
import seiko from "@/components/pages/brands/seiko";
import citizen from "@/components/pages/brands/citizen";
import iwc from "@/components/pages/brands/iwc";
import tagheuer from "@/components/pages/brands/tagheuer";
import gucci from "@/components/pages/brands/gucci";
import bvlgari from "@/components/pages/brands/bvlgari";
import patek from "@/components/pages/brands/patek";
import clock from "@/components/pages/brands/clock";
import other from "@/components/pages/brands/other";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "*",
    name: Home,
    component: Home,
  },
  /*
  {
    path: "/test",
    name: "test",
    component: test,
  },
  */
  {
    path: "/repairCenter",
    name: "repairCenter",
    component: repairCenter,
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
  },
  {
    path: "/timeRepairOnline",
    name: "timeRepairOnline",
    component: timeRepairOnline,
    meta: {
      title: "タイムリペアオンライン　時計修理専門店-timeRepair- (ONLINE)",
    },
  },

  {
    path: "/QandA",
    name: "QandA",
    component: QandA,
  },
  {
    path: "/ohmiyaten",
    name: "ohmiyaten",
    component: ohmiyaten,
  },

  {
    path: "/price",
    name: "price",
    component: price,
  },
  {
    path: "/Stores",
    name: "Stores",
    component: Stores,
  },
  {
    path: "/news",
    name: "news",
    component: news,
  },
  {
    path: "/inputAchievement",
    name: "inputAchievement",
    component: inputAchievement,
  },
  //もろもろ

  {
    path: "/contract",
    name: "contract",
    component: contract,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: privacy,
  },
  {
    path: "/terms",
    name: "terms",
    component: terms,
  },
  //使用停止
  /*
  {
    path: "/StaffLogin",
    name: "StaffLogin",
    component: StaffLogin,
  },
  {
    path: "/numberCall",
    name: "numberCall",
    component: numberCall,
  },
  {
    path: "/numberCallView",
    name: "numberCallView",
    component: numberCallView,
    meta: { requiresAuth: true },
  },

  {
    path: "/inputCustomer",
    name: "inputCustomer",
    component: inputCustomer,
    meta: { requiresAuth: true },
  },
  {
    path: "/entryForm",
    name: "entryForm",
    component: entryForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/statusView",
    name: "statusView",
    component: statusView,
    meta: { requiresAuth: true },
  },
  {
    path: "/InquiryForm",
    name: "InquiryForm",
    component: InquiryForm,
  },
*/
  //ブランド
  {
    path: "/rolex",
    name: "rolex",
    component: rolex,
    meta: { title: "ロレックス　時計修理専門店-timeRepair- (ROLEX)" },
  },
  {
    path: "/omega",
    name: "omega",
    component: omega,
    meta: { title: "オメガ　時計修理専門店-timeRepair- (OMEGA)" },
  },
  {
    path: "/longines",
    name: "longines",
    component: longines,
    meta: { title: "ロンジン　時計修理専門店-timeRepair- (LONGINES)" },
  },
  {
    path: "/hermes",
    name: "hermes",
    component: hermes,
    meta: { title: "エルメス　時計修理専門店-timeRepair- (Hermès)" },
  },
  {
    path: "/cartier",
    name: "cartier",
    component: cartier,
    meta: { title: "カルティエ　時計修理専門店-timeRepair- (cartier)" },
  },
  {
    path: "/seiko",
    name: "seiko",
    component: seiko,
    meta: { title: "セイコー　時計修理専門店-timeRepair- (SEIKO)" },
  },
  {
    path: "/citizen",
    name: "citizen",
    component: citizen,
    meta: { title: "シチズン　時計修理専門店-timeRepair- (citizen)" },
  },
  {
    path: "/iwc",
    name: "iwc",
    component: iwc,
    meta: { title: "IWC　時計修理専門店-timeRepair- (インターナショナル)" },
  },
  {
    path: "/tagheuer",
    name: "tagheuer",
    component: tagheuer,
    meta: { title: "タグホイヤー　時計修理専門店-timeRepair- (TAG HEUER)" },
  },
  {
    path: "/gucci",
    name: "gucci",
    component: gucci,
    meta: { title: "グッチ　時計修理専門店-timeRepair- (GUCCI)" },
  },
  {
    path: "/bvlgari",
    name: "bvlgari",
    component: bvlgari,
    meta: { title: "ブルガリ　時計修理専門店-timeRepair- (BVLGARI)" },
  },
  {
    path: "/patek",
    name: "patek",
    component: patek,
    meta: {
      title: "パテックフィリップ　時計修理専門店-timeRepair- (PATEK PHILIPES)",
    },
  },
  {
    path: "/clock",
    name: "clock",
    component: clock,
    meta: { title: "置・掛時計　時計修理専門店-timeRepair- (CLOCK)" },
  },
  {
    path: "/other",
    name: "other",
    component: other,
    meta: { title: "高級　時計修理専門店-timeRepair- " },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  top: 0,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !Store.state.Token
  ) {
    if (sessionStorage.from == "") {
      sessionStorage.from = "/Home";
    }
    sessionStorage.from = to.fullPath;
    next({ name: "Home" });
  } else {
    if (to.name == null || to.name == "") {
      var nextPath = to.path.slice(1);

      next({ name: nextPath });
    } else {
      next();
    }
  }
});

export default router;
