<template>
  <div class="mt-10">
    <v-row justify="center">
      <v-col xs="12" sm="12" md="6" lg="3" xl="3">
        <v-card height="350px" max-width="250px" flat class="top-text-box">
          <div>
            <figure class="hover-parent">
              <div>
                <v-img class="img" src="/imgs/img5.jpg" />
                <v-icon class="mt-3 mb-2">mdi-battery-off-outline</v-icon>
                <div>
                  <h2 class="mb-n2">電池交換</h2>
                  <span class="font-9">BATTERY</span>
                  <h3 class="mt-2">〔料金表〕</h3>
                  <span>国産：1500円～</span><br />
                  <span>輸入：1500円～</span>
                </div>
              </div>

              <figcaption class="hover-mask">
                <table>
                  <tr>
                    <td>
                      <h3 class="mb-n2">電池交換</h3>
                    </td>
                  </tr>
                  <tr>
                    <td @click="to_hash('price', '#BATTERYPRICE')">
                      <v-btn
                        text
                        class="menu-btn mt-4"
                        x-large
                        color="white"
                        @click="to_hash('price', '#BATTERYPRICE')"
                        >PRICE<br />【料金表】</v-btn
                      >
                    </td>
                  </tr>
                  <tr class="font-9">
                    <td class="comment">
                      電池交換 ”最短5分”<br />
                      基本的に電池交換は店頭その場で行っています。<br />
                      セイコー、シチズン、ダニエルウェリントン、スカーゲン
                      オメガ、カルティエ、グッチ、エルメスなど
                      国産・輸入・ブランド品など基本的にどんな時計でもお受けしております。<br />
                      他店で断られたものもご相談ください。
                    </td>
                  </tr>

                  <tr>
                    <td class="comment"></td>
                  </tr>
                </table>
              </figcaption>
            </figure>
          </div>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="3" xl="3">
        <v-card height="350px" max-width="250px" flat class="top-text-box">
          <div>
            <figure class="hover-parent">
              <div>
                <v-img class="img" src="/imgs/img1.jpg" />
                <v-icon class="mt-3 mb-2">mdi-cog</v-icon>
                <div>
                  <h2 class="mb-n2">分解掃除</h2>
                  <span class="font-9">OVERHAUL</span>
                  <h3 class="mt-2">〔詳細はこちら〕</h3>
                  <span>国産：7,700円～</span><br />
                  <span>輸入：10,000円～</span>
                </div>
              </div>

              <figcaption class="hover-mask">
                <table>
                  <tr>
                    <td>
                      <h3 class="mb-n2">分解掃除</h3>
                    </td>
                  </tr>
                  <tr>
                    <td @click="to_hash('price', '#PRICETOP')">
                      <v-btn
                        text
                        class="menu-btn mt-4"
                        x-large
                        color="white"
                        @click="to_hash('price', '#PRICETOP')"
                        >PRICE<br />【料金表】</v-btn
                      >
                    </td>
                  </tr>
                  <tr class="font-9">
                    <td class="comment">
                      時計は精密機械です。<br />
                      年数が経つと歯車の油が劣化、乾燥してしまいます。<br />
                      その状態で使用すると歯車など内部パーツの摩耗につながります。
                      また、パッキンなどの劣化などにより防水性能も経年で低下いたします。<br />
                      クォーツ時計で４～５年に一度、機械式時計では３～4年に一度<br />
                      オーバーホール（分解掃除）をお勧めいたします。
                    </td>
                  </tr>

                  <tr>
                    <td class="comment"></td>
                  </tr>
                </table>
              </figcaption>
            </figure>
          </div>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="3" xl="3">
        <v-card height="350px" max-width="250px" flat class="top-text-box">
          <div>
            <figure class="hover-parent">
              <div>
                <v-img class="img" src="/imgs/img2.jpg" />
                <v-icon class="mt-3 mb-2">mdi-border-color</v-icon>
                <div>
                  <h2 class="mb-n2">時計バンド</h2>
                  <span class="font-9">STRAP</span>
                  <h3 class="mt-2">〔詳細はこちら〕</h3>
                  <span></span><br />
                  <span></span>
                </div>
              </div>

              <figcaption class="hover-mask">
                <table>
                  <tr>
                    <td>
                      <h3 class="mb-n2">時計バンド</h3>
                    </td>
                  </tr>
                  <tr class="mt-5 mb-5">
                    <td><br /><br /></td>
                  </tr>
                  <tr class="font-9">
                    <td class="comment">
                      時計バンドは系列の【watch-wrist-wear】<br />
                      <a
                        href="https://store.shopping.yahoo.co.jp/watch-wrist-wear/"
                        target="_blank"
                        >オンラインショップ</a
                      >にてお取扱しています。<br />
                      オーダーバンドも製作可能です。
                    </td>
                  </tr>
                  <tr>
                    <td class="font-12 mt-5">
                      <tr>
                        <td>
                          <a
                            class="deco_none"
                            href="https://store.shopping.yahoo.co.jp/watch-wrist-wear/"
                            target="_blank"
                            >〔詳細はこちら→〕</a
                          >
                        </td>
                      </tr>
                      <a
                        href="https://store.shopping.yahoo.co.jp/watch-wrist-wear/"
                        target="_blank"
                        ><img width="200px" src="/imgs/www.jpg"
                      /></a>
                    </td>
                  </tr>
                  <tr>
                    <td class="comment"></td>
                  </tr>
                </table>
              </figcaption>
            </figure>
          </div>
        </v-card>
      </v-col>

      <v-col xs="12" sm="12" md="6" lg="3" xl="3">
        <v-card height="350px" max-width="250px" flat class="top-text-box">
          <div>
            <figure class="hover-parent">
              <div>
                <v-img class="img" src="/imgs/img4.jpg" />
                <v-icon class="mt-3 mb-2">mdi-watch</v-icon>
                <div>
                  <h2 class="mb-n2">ウォッチ</h2>
                  <span class="font-9">WATCH</span>
                  <td class="comment pl-3 pr-3">
                    タイムリペアではリユース品、ヴィンテージウォッチを<br />
                    オーバーホール、点検、調整して販売をしております。
                  </td>
                </div>
              </div>

              <figcaption class="hover-mask">
                <table>
                  <tr>
                    <td>
                      <span class="ovh_title">ウォッチ</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-12"><a href="">coming soon</a></td>
                  </tr>
                  <tr class="font-9">
                    <td class="comment">
                      タイムリペアではリユース品、ヴィンテージウォッチを<br />
                      オーバーホール、点検、調整して販売をしております。<br />
                    </td>
                  </tr>

                  <tr>
                    <td class="comment"></td>
                  </tr>
                </table>
              </figcaption>
            </figure>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return {
      isLoad: false,
      achievement: [],
    };
  },
  mixins: [doAxios],

  methods: {
    async getAchievement() {
      const php = "number_call/getAchievement";
      this.achievement = await this.doAxios({ php });
    },
    to_hash(path, Hash) {
      if (path == this.$route.name) {
        this.$router.push({ hash: "" });
        this.$router.push({ hash: Hash });
      } else {
        this.$router.push({ name: path, hash: Hash });
      }
    },
  },
  mounted() {
    this.getAchievement();
  },
  watch: {},
};
</script>
<style scoped>
.img {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.top-text-box {
  background-color: #ffffff; /* 背景色指定 */
  position: relative;
  width: 100%;
  margin: 0 auto; /* 中央寄せ */
  text-align: center;
}
.top-text-box h2 {
  font-family: honoka, serif;
}
.font-9 {
  font-size: 9pt;
}
.font-12 {
  font-size: 1.2em;
}
.red-s {
  text-decoration: line-through 1px solid #ff0000;
}
.title {
  width: 90%;
  color: #fff;
  background: #4f7594;
  transition: all 0.4s;
  transform: skewX(-16deg);
  text-align: left;
}
figure.hover-parent {
  width: 250px;
  height: 350px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  transition: 1s all;
  border-radius: 5px;
  padding-bottom: 5px;
}

figure.hover-parent:hover {
  transform: scale(1.1, 1.1);
  transition: 0.7s all;
}
figcaption.hover-mask {
  width: 250px;
  height: 350px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(19, 53, 128, 0.8);
  opacity: 0;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  padding-bottom: 10px;
}

figcaption.hover-mask:hover {
  opacity: 1;
  transition: all 0.6s ease;
}

.comment {
  white-space: pre-line;
  font-size: 10pt;
  text-align: left;
}
.comment a {
  font-size: 14px;
  color: white;
  text-decoration: none;
}
.comment a,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none;
}
.ovh_title {
  white-space: pre-line;
}
.menu-btn {
  text-decoration: underline;
  text-decoration-color: white;
}
.deco_none {
  text-decoration: underline;
  color: white;
}
</style>
