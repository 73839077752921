<template>
  <div class="mt-10">
    <div ref="TOP"></div>

    <div class="top-box">
      <div class="slide">
        <img width="100%" src="/imgs/onlinetop.png" alt="" />
      </div>
      <p class="top-left">TimeRepair<br />ONLINE<br /></p>
    </div>

    <v-card style="background-color: #cfe4f9" class="mt-5 mb-16" flat>
      <!-- 修理の流れ Section Start ---------------------------------------------------------------->

      <v-row>
        <v-col cols="12" class="text-center animation_box mb-5 mt-5">
          <h2 class="section-title">
            <b style="color: #004d99">―</b>修理の流れ
            <b style="color: #004d99">―</b>
          </h2>
          <v-row>
            <v-col>
              <v-card-subtitle
                >お問い合わせから修理、お届けまでの一連の流れのご案内です。</v-card-subtitle
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- お問合せ-->
      <div>
        <v-card class="counter mx-auto" width="80%" flat>
          <div>
            <v-row>
              <div
                class="icon"
                v-show="
                  $vuetify.breakpoint.xl ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.md
                "
              >
                <p>1</p>
              </div>
              <v-col
                class="my-auto"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="3"
                xl="3"
              >
                <div class="text-center">
                  <h3>お問合せ</h3>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <v-card class="mx-auto" width="90%" flat>
                  <p>
                    <b>
                      <v-btn
                        color="pink"
                        small
                        dark
                        href="mailto:online@time-repair.com?subject=%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body= %0d%0a 
%E3%80%90%E3%81%8A%E5%90%8D%E5%89%8D%E3%80%91%EF%BC%9A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20 様 %0d%0a  %0d%0a
%E3%80%90%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%80%91%EF%BC%9A%20%0d%0a
%E3%83%A2%E3%83%87%E3%83%AB%E7%95%AA%E5%8F%B7%E7%AD%89%E3%82%8F%E3%81%8B%E3%82%8C%E3%81%B0%E8%A8%98%E8%BC%89%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84(%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20)  %0d%0a %0d%0a
%E3%80%90%E7%97%87%E7%8A%B6%E3%80%91%EF%BC%9A%20 %0d%0a  %0d%0a 
%E2%80%BB%E5%8F%AF%E8%83%BD%E3%81%A7%E3%81%82%E3%82%8C%E3%81%B0%E3%81%8A%E6%99%82%E8%A8%88%E3%81%AE%E5%86%99%E7%9C%9F%E3%82%92%E6%B7%BB%E4%BB%98%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82"
                      >
                        メール からのお問い合わせはコチラ</v-btn
                      ></b
                    ><br />
                    メールアドレス：online@time-repair.jp<br />
                    メールからお問い合わせください。<br />
                    写真を登録していただけますと、より正確なお見積もりが可能になります。<br />
                    <br />
                    <b>
                      <v-btn
                        color="green"
                        dark
                        href="https://lin.ee/1LW6qTQ"
                        target="_blank"
                        small
                      >
                        LINE からのお問い合わせはコチラ</v-btn
                      ></b
                    ><br />
                    LINEアプリで友だち追加後に時計の写真をお送りください。<br />お見積り完了後、お見積り結果をLINEのメッセージでご連絡いたします。<br /><br />
                    <b class="mr-5">
                      <v-btn
                        clsss="ml-5"
                        small
                        color="primary"
                        dark
                        href="tel:080-7102-8107"
                      >
                        電話で相談 </v-btn
                      ><br />
                      専用携帯電話：080-7102-8107</b
                    >
                    <br />
                    受付時間：平日10：00～17：00 土日祝 休み<br />
                    <b>大宮店：048-662-9300</b>
                    <br />
                    受付時間：平日10：30～19：30（金曜定休） 土日祝
                    10：30～19：30<br />
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <!-- arrow -->
      <div class="arrow center-block mt-5 mb-3">
        <div>
          <div>
            <p>▼</p>
          </div>
        </div>
      </div>
      <!-- arrow end-->

      <!-- お見積りのご連絡-->

      <div>
        <v-card class="counter mx-auto" width="80%" flat>
          <div>
            <v-row>
              <div
                class="icon"
                v-show="
                  $vuetify.breakpoint.xl ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.md
                "
              >
                <p>2</p>
              </div>
              <v-col
                class="my-auto"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="3"
                xl="3"
              >
                <div class="text-center">
                  <h3>お見積りのご連絡</h3>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <v-card class="mx-auto" width="90%" flat>
                  <p>
                    登録して頂いたアドレスに担当者より大まかな仮見積もりをご連絡いたします。
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

      <!-- arrow -->
      <div class="arrow center-block mt-5 mb-3">
        <div>
          <div>
            <p class="fas fa-angle-down">▼</p>
          </div>
        </div>
      </div>
      <!-- arrow end-->

      <!-- 送付キット発送-->

      <div>
        <v-card class="counter mx-auto" width="80%" flat>
          <div>
            <v-row>
              <div
                class="icon"
                v-show="
                  $vuetify.breakpoint.xl ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.md
                "
              >
                <p>3</p>
              </div>
              <v-col
                class="my-auto"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="3"
                xl="3"
              >
                <div class="text-center">
                  <h3>送付キット発送</h3>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <v-card class="mx-auto" width="90%" flat>
                  <p>
                    仮見積もりをご了承頂けましたら送付キットをお送りいたします。
                  </p>

                  <v-row>
                    <v-col cols="9" class="row sendingkit">
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                          <img
                            src="imgs/flow/01.jpg"
                            width="100%"
                            class="ml-2"
                            alt="送付キット"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                          <img
                            class="ml-2"
                            src="imgs/flow/02.jpg"
                            width="100%"
                            alt="送付キット2"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="caption">
                      送付キット内容：専用BOX、固定用フィルムパッド、梱包用エアパッキン、送り状、割れ物シール、受付表
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

      <!-- arrow -->
      <div class="arrow center-block mt-5 mb-3">
        <div>
          <div>
            <p class="fas fa-angle-down">▼</p>
          </div>
        </div>
      </div>
      <!-- arrow end-->
      <!-- 送付キット返送-->
      <div>
        <v-card class="counter mx-auto" width="80%" flat>
          <div>
            <v-row>
              <div
                class="icon"
                v-show="
                  $vuetify.breakpoint.xl ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.md
                "
              >
                <p>4</p>
              </div>
              <v-col
                class="my-auto"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="3"
                xl="3"
              >
                <div class="text-center">
                  <h3>送付キット返送</h3>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <v-card class="mx-auto" width="90%" flat>
                  <v-row>
                    <v-col cols="12">
                      <p>
                        下記画像を参考にして腕時計を梱包してください。<br />
                        受付表に必要事項をご記入して頂き、送り状にも住所、お名前をご記入してください。<br />
                        梱包後、ヤマト運輸に集荷のご依頼をして弊社へご返送ください。<br />
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <img
                        class="img-fluid"
                        src="imgs/flow/03.jpg"
                        width="100%"
                        alt="送付キット3"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

      <!-- arrow -->
      <div class="arrow center-block mt-5 mb-3">
        <div>
          <div>
            <p class="fas fa-angle-down">▼</p>
          </div>
        </div>
      </div>
      <!-- arrow end-->

      <!-- 本見積もりご連絡-->
      <div>
        <v-card class="counter mx-auto" width="80%" flat>
          <div>
            <v-row>
              <div
                class="icon"
                v-show="
                  $vuetify.breakpoint.xl ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.md
                "
              >
                <p>5</p>
              </div>
              <v-col
                class="my-auto"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="3"
                xl="3"
              >
                <div class="text-center">
                  <h3>本見積ご連絡</h3>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <v-card class="mx-auto" width="90%" flat>
                  <v-row>
                    <v-col cols="12">
                      <p>
                        お送り頂いた時計内部を拝見しお見積り金額をご連絡いたします。<br />
                        お見積もりには1週間前後お時間を頂戴いたします。<br />
                        <br />
                        <b>キャンセルの場合</b><br />
                        お見積り後にキャンセルされる場合の時計の返送は「着払い」とさせていただきますので、予めご了承ください。
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

      <!-- arrow -->
      <div class="arrow center-block mt-5 mb-3">
        <div>
          <div>
            <p class="fas fa-angle-down">▼</p>
          </div>
        </div>
      </div>
      <!-- arrow end-->

      <!-- 技術者による修理-->
      <div>
        <v-card class="counter mx-auto" width="80%" flat>
          <div>
            <v-row>
              <div
                class="icon"
                v-show="
                  $vuetify.breakpoint.xl ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.md
                "
              >
                <p>6</p>
              </div>
              <v-col
                class="my-auto"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="3"
                xl="3"
              >
                <div class="text-center">
                  <h3>技術者による修理</h3>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <v-card class="mx-auto" width="90%" flat>
                  <v-row>
                    <v-col cols="12">
                      <p>
                        お見積り金額をご了承いただけましたら、1級時計修理技能士を中心に専任の技術者が丁寧にスピーディーに修理いたします。
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

      <!-- arrow -->
      <div class="arrow center-block mt-5 mb-3">
        <div>
          <div>
            <p class="fas fa-angle-down">▼</p>
          </div>
        </div>
      </div>
      <!-- arrow end-->
      <!-- お支払い-->
      <div>
        <v-card class="counter mx-auto" width="80%" flat>
          <div>
            <v-row>
              <div
                class="icon"
                v-show="
                  $vuetify.breakpoint.xl ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.md
                "
              >
                <p>7</p>
              </div>
              <v-col
                class="my-auto"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="3"
                xl="3"
              >
                <div class="text-center">
                  <h3>お支払について</h3>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <v-card class="mx-auto" width="90%" flat>
                  <p>
                    クレジットカードでお支払いください。下記のカードがご利用いただけます。<br />
                    <br />

                    <img
                      class="credit ml-3"
                      src="imgs/flow/squarelogo.png"
                      width="90%"
                      alt="クレジットカード"
                    />
                    <br />
                    <br />
                    <span class="precautions">
                      ※修理・作業費用が10,000円(税別)以下の場合、別途1,500円(税別)の往復配送料金を頂戴致します。</span
                    >
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

      <!-- arrow -->
      <div class="arrow center-block mt-5 mb-3">
        <div>
          <div>
            <p class="fas fa-angle-down">▼</p>
          </div>
        </div>
      </div>
      <!-- arrow end-->

      <!-- 発送-->

      <div>
        <v-card class="counter mx-auto" width="80%" flat>
          <div>
            <v-row>
              <div
                class="icon"
                v-show="
                  $vuetify.breakpoint.xl ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.md
                "
              >
                <p>8</p>
              </div>
              <v-col
                class="my-auto"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="3"
                xl="3"
              >
                <div class="text-center">
                  <h3>発送</h3>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <v-card class="mx-auto" width="90%" flat>
                  <p>修理完了した腕時計をご自宅へ発送いたします。</p>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </v-card>

    <!-- 修理の流れ Section End -->

    <v-card flat>
      <qand-a></qand-a>
    </v-card>

    <div class="mt-16 mb-5 text-center">
      <contract-footer class="mt-15"></contract-footer>
    </div>
    <page-footer></page-footer>
    <div class="pb-16"></div>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
import qandA from "@/components/pages/QandA";
import contractFooter from "@/components/organisms/contractFooter";
import pageFooter from "@/components/organisms/pageFooter";

export default {
  data() {
    return {};
  },
  mixins: [doAxios],
  components: {
    contractFooter,
    qandA,
    pageFooter,
  },
  methods: {},
  mounted() {
    if (this.$route.hash.match(/^#/)) {
      const el = this.$refs[this.$route.hash.slice(1)];
      el.scrollIntoView({ behavior: "smooth" });
    } else {
      const el = this.$refs["TOP"];
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
  watch: {},
};
</script>
<style scoped>
/*枠の大きさ*/
.top-box {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  background-color: rgba(19, 53, 128, 0.8);
}

.top-left {
  position: absolute;
  top: 20%;
  left: 10%;
  color: white;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.14em;
  transform: scaleX(0.825);
  transform-origin: left center;
  text-shadow: 3px 4px 3px rgb(0, 89, 255);
}

.slide {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  background: white;
}

.slide img {
  position: absolute;
  top: 20%;
  left: 50%;

  width: calc(100% + 100px);
  opacity: 0;
  animation: slideshow 8s both 1;
}
.event-title {
  width: 80%;
  color: darkblue;
  background: linear-gradient(to right, pink, #ffe8ff);
  margin: 15px auto 0;
}

/*--------------------------------------------------------------
# 修理の流れ
--------------------------------------------------------------*/

.icon {
  background-image: linear-gradient(0deg, #3399ff 0, #80bfff 100%);
  color: #fff;
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: -22px;
  margin-top: -25px;
}
.counter {
  box-shadow: 0 8px 24px #e2e8ed;
}
.counter-section .counter i {
  font-size: 60px;
  line-height: 60px;
  color: #000;
}
.counter-section .counter p {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  padding-right: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.counter-section .counter .counterUp {
  color: #888;
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: 700;
}
.counter-section .counter h3 {
  font-size: 22px;
  color: #000;
  font-weight: bold;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sendingkit {
  padding-right: 30px;
}
.arrow {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.arrow p {
  font-size: 40px;
  color: #000;
  text-align: center;
  display: block;
  margin-top: -10px;
  margin-bottom: 20px;
}
img.credit {
  padding-top: 10px;
  max-width: 100%;
}
video {
  max-width: 100%;
  height: auto;
  border: none;
}
.flowtext {
  padding: 100px 0;
}
.flowtext2 {
  padding: 40px 0;
}
.flowtext3 {
  padding: 60px 0;
}
.caption {
  padding-top: 8px;
  line-height: 1.4;
}
span.precautions {
  font-size: 14px;
}
.flowwrapper {
  margin-bottom: 10px;
}
.section-title {
  position: relative;
  font-size: 22px;
  line-height: 24px;
  padding-bottom: 10px;
}
.section-title-header p.wow {
  font-size: 17px;
  animation: slideshow 8s both 1;
}
div.animation_box {
  padding: 10px;
  animation: fadeIn 5s ease 1s 1 normal backwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideshow {
  0% {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  10% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
</style>
