export default{
    
data(){
    return {
      res:{}
    }
},
 methods: {

     async doAxios(setOption){

         const headers= {
            'ContentType': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'X-SPECIAL-ID': this.$store.state.Id,           
            'X-SPECIAL-TOKEN': this.$store.state.Token,
            'X-SPECIAL-STORETOKEN': this.$store.state.LoginToken,            

         };

         const url = 'https://enhance-watchservice.com/time_repair/api/' + setOption.php;

         if(setOption.setData==null || setOption.setData==""){

            await this.axios.post(url,"", {headers: headers})
            .then((response) => {
                if(response.data.code==500){
                    this.doLogout()                
                  }
                if(response.data.code==501){                   
                     this.doLogout()
                  }             
                this.res = response.data
                return this.res
            })
            .catch(function (error) {
                console.log(error);
                return 
            });

         }else{

            const json_data =JSON.stringify( setOption.setData );
   
            await this.axios.post(url,json_data, {headers: headers})
            .then((response) => {
             if(response.data.code==500){
                this.doLogout()                
              }
              if(response.data.code==501){
                this.res ="null"
               return this.res               
             }
                this.res = response.data
                return this.res
            })
            .catch(function (error) {
                console.log(error);

                return  this.res
 
            });
         }
//console.log(this.res)
        return  this.res
     },
        async doLogout(){
        this.$store.dispatch('authToken', '')
        this.$store.dispatch('authId', '')   
        this.$router.push('/errorView')
        return
        },
 }
}