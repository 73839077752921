<template >
<div>
<div class="progress_wrap" :data-order="progress_order">

  <span :class="time_image_change()"></span>

  </div>
</div>

</template>
<script>


export default {
  name: 'sample',
    data(){
    return {
      progress_order:"right",
      timeout:"",
      query:"",
      value:0,
      show:false,
      img_item:"img_item_r", 
 
    }
  },

methods: {
  time_image_change(){
    if( this.value ==100){
      this.img_item = "img_item_l"
      this.progress_order =  "Left"
    }else{
       this.img_item = "img_item_r"   
             this.progress_order =  "right"    
    }

    return this.img_item

  },
    queryAndIndeterminate () {
      this.query = true
      this.show = true
      this.value = 0
      setTimeout(() => {
        this.query = false

        this.interval = setInterval(() => {
          if (this.value === 100) {
            clearInterval(this.interval)
            this.show = false
            return setTimeout(this.queryAndIndeterminate,1000)
          }
          this.value += 100
        }, 1000)
      },1000)

    },
   
  
 },
  beforeDestroy () {
    clearInterval(this.interval)
  },  
  mounted:function(){
     this.queryAndIndeterminate ()
  }
}
</script>
<style scoped>

.img_item_r {

  position: relative;
  right: 0px;
  top: 0;
  animation-name: GoLeft ;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
	width: 100px;
    height: 100px;
    border-radius: 10px;
    background-image: url(https://time-repair.jp/ohmiya/imgs/trabbit_gif.gif);
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    display: block;

}
.img_item_l {

  position: relative;
  left: 0px;
  top: 0;
  animation-name: GoRight ;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
	width: 100px;
  height: 100px;
  border-radius: 10px;
  background-image: url(https://time-repair.jp/ohmiya/imgs/trabbit_gif.gif);
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  display: block;
    transform: scale(-1, 1);
  animation-fill-mode: forwards;


}
.progress_wrap[data-order="left"] {
    animation-name: GoLeft;

}
.progress_wrap[data-order="right"] {
    animation-name: GoRight;

}


@keyframes GoRight {
    0% {
        Left: 20%
    }
    100% {
        Left: 80%;
    }
}
@keyframes GoLeft {
    0% {
        Left: 80%;
    
    }
    100% {
        Left: 20%;
    }
}

</style>