<template>
  <div class="mt-10">
    <v-row justify="center">
      <v-col cols="12"
        ><v-card-title
          ><div class="title">
            <span class="ml-5">修理事例</span>
          </div></v-card-title
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col
        xs="12"
        sm="12"
        md="3"
        lg="3"
        xl="3"
        v-for="(item, i) in achievement"
        :key="i"
        ><div>
          <v-card height="350px" width="250px" flat class="top-text-box">
            <figure
              class="hover-parent"
              v-on:mouseover="mouseOverAction"
              v-on:mouseleave="mouseLeaveAction"
            >
              <v-img height="350px" :src="item.img_url" />
              <div class="parent-text" v-if="!isHover">
                <b>【 詳細はタップ 】</b>
              </div>
              <figcaption class="hover-mask">
                <table>
                  <tr>
                    <td>
                      <span class="ovh_title">{{ item.title }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-12">
                      {{ item.price }}
                    </td>
                  </tr>
                  <tr v-show="item.bprice" class="font-9">
                    <td>
                      参考デパート価格<br />
                      <s class="red-s">{{ item.bprice }}</s>
                    </td>
                  </tr>

                  <tr>
                    <td class="comment">
                      {{ item.comment }}
                    </td>
                  </tr>
                </table>
              </figcaption>
            </figure>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12"
        ><v-card-text class="font-9"
          >表示金額は修理時点の金額となります。価格は全て税込みです。</v-card-text
        ></v-col
      >
    </v-row>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return {
      isLoad: false,
      isHover: false,
      achievement: [],
    };
  },
  mixins: [doAxios],
  props: ["propsItems"],
  methods: {
    async getAchievement() {
      let brandId = 0;

      if (this.propsItems == null || this.propsItems == "") {
        brandId = null;
      } else if (this.propsItems.brand_id > 0) {
        brandId = this.propsItems["brand_id"];
      } else {
        brandId = 0;
      }
      const php = "number_call/getAchievement";
      const setData = {
        brand_id: brandId,
      };
      const res = await this.doAxios({ php, setData });

      this.achievement = res;
    },
    mouseOverAction() {
      this.isHover = true;
    },
    mouseLeaveAction() {
      this.isHover = false;
    },
  },
  mounted() {
    this.getAchievement();
  },
  watch: {},
};
</script>
<style scoped>
.parent-text {
  position: absolute;
  top: 100px;
  left: 25px;
  color: #ffffff;
  font-size: 1.5rem;
}
.top-text-box {
  background-color: #ffffff; /* 背景色指定 */
  position: relative;
  width: 100%; /* 幅指定 */
  margin: 0 auto; /* 中央寄せ */
  text-align: center;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
}
.font-9 {
  font-size: 9pt;
}
.font-12 {
  font-size: 1.2em;
}
.red-s {
  text-decoration: line-through 1px solid #ff0000;
}
.title {
  width: 90%;
  color: #fff;
  background: #4f7594;
  transition: all 0.4s;
  transform: skewX(-16deg);
  text-align: left;
}
figure.hover-parent {
  width: 250px;
  height: 350px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  transition: 1s all;
}

figure.hover-parent:hover {
  transform: scale(1.2, 1.2);
  transition: 1s all;
}
figcaption.hover-mask {
  width: 250px;
  height: 350px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 30px;
}

figcaption.hover-mask:hover {
  opacity: 1;
  transition: all 0.6s ease;
}

.comment {
  white-space: pre-line;
  font-size: 10pt;
  text-align: left;
}
.ovh_title {
  white-space: pre-line;
}
</style>
