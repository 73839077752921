<template>
  <div class="mt-10">
    <h2 class="ml-10 mb-5">ブランド別修理事例</h2>
    <v-row justify="center" dense>
      <v-col
        xs="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
        v-for="(brand, index) in brands"
        :key="index"
      >
        <v-card
          width="250px"
          flat
          class="top-text-box"
          @click="to_hash(brand['hash'])"
        >
          <div>
            <figure class="hover-parent">
              <div>
                <v-img class="img" :src="'/imgs/brands/' + brand['src']" />

                <div>
                  <h2 class="mb-n2">{{ brand["nameEN"] }}</h2>
                  <span class="font-9">{{ brand["nameJP"] }}</span>
                  <v-btn x-large color="primary" text
                    >《詳細はクリック⇒》</v-btn
                  >
                </div>
              </div>
            </figure>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return {
      isLoad: false,
      achievement: [],
      brands: [
        {
          nameJP: "ロレックス",
          nameEN: "ROLEX",
          src: "rolex_gmt.jpeg",
          hash: "rolex",
        },
        {
          nameJP: "オメガ",
          nameEN: "OMEGA",
          src: "omegaQM.jpeg",
          hash: "omega",
        },
        {
          nameJP: "カルティエ",
          nameEN: "cartier",
          src: "CARTIER_QL.jpeg",
          hash: "cartier",
        },
        {
          nameJP: "エルメス",
          nameEN: "Hermès",
          src: "hermesQL.jpeg",
          hash: "hermes",
        },
        {
          nameJP: "セイコー/グランドセイコー/クレドール",
          nameEN: "SEIKO/GS/CREDR",
          src: "GS_QM.jpeg",
          hash: "seiko",
        },
        {
          nameJP: "シチズン",
          nameEN: "CITIZEN",
          src: "CT_AM.jpeg",
          hash: "citizen",
        },
        {
          nameJP: "インターナショナル",
          nameEN: "IWC",
          src: "IWC_AC.jpeg",
          hash: "iwc",
        },
        {
          nameJP: "タグホイヤー",
          nameEN: "TAG HEUER",
          src: "TAG_AM.jpeg",
          hash: "tagheuer",
        },
        {
          nameJP: "ロンジン",
          nameEN: "LONGINES",
          src: "LONGINES_QL.jpeg",
          hash: "longines",
        },
        {
          nameJP: "グッチ",
          nameEN: "GUCCI",
          src: "GUCCI_QL.jpeg",
          hash: "gucci",
        },
        {
          nameJP: "ブルガリ",
          nameEN: "BVLGARI",
          src: "BVLGARI_QL.jpeg",
          hash: "bvlgari",
        },
        {
          nameJP: "パテックフィリップ",
          nameEN: "PATEK PHILIPPE",
          src: "PATEKPHILIPPE_HM.jpeg",
          hash: "patek",
        },
        {
          nameJP: "クロック",
          nameEN: "CLOCK",
          src: "clock.jpg",
          hash: "clock",
        },
        {
          nameJP: "その他",
          nameEN: "other",
          src: "other.jpeg",
          hash: "other",
        },
      ],
    };
  },
  mixins: [doAxios],

  methods: {
    async getAchievement() {
      const php = "number_call/getAchievement";
      this.achievement = await this.doAxios({ php });
    },
    to_hash(path, Hash) {
      const rootPath = this.$route.name;
      if (rootPath == path) {
        return;
      } else {
        if (path == this.$route.name) {
          this.$router.push({ hash: "" });
          this.$router.push({ hash: Hash });
        } else {
          this.$router.push({ name: path, hash: Hash });
        }
      }
    },
  },
  mounted() {
    this.getAchievement();
  },
  watch: {},
};
</script>
<style scoped>
.img {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.top-text-box {
  background-color: #ffffff; /* 背景色指定 */
  position: relative;
  width: 250px;
  margin: 0 auto; /* 中央寄せ */
  text-align: center;
}
.top-text-box h2 {
  font-family: honoka, serif;
}
.font-9 {
  font-size: 9pt;
}
.font-12 {
  font-size: 1.2em;
}
.red-s {
  text-decoration: line-through 1px solid #ff0000;
}
.title {
  width: 90%;
  color: #fff;
  background: #4f7594;
  transition: all 0.4s;
  transform: skewX(-16deg);
  text-align: left;
}
figure.hover-parent {
  width: 250px;
  height: 250px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  box-shadow: 0 10px 25px 0 rgba(19, 53, 128, 0.3);
  border-radius: 5px;
}

.comment {
  white-space: pre-line;
  font-size: 10pt;
  text-align: left;
}
.comment a {
  font-size: 14px;
  color: white;
  text-decoration: none;
}
.comment a,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none;
}
.ovh_title {
  white-space: pre-line;
}
</style>
