import { render, staticRenderFns } from "./iwc.vue?vue&type=template&id=fd69ab18&scoped=true&"
import script from "./iwc.vue?vue&type=script&lang=js&"
export * from "./iwc.vue?vue&type=script&lang=js&"
import style0 from "./iwc.vue?vue&type=style&index=0&id=fd69ab18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd69ab18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VCol,VRow})
