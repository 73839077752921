<template >
  <div ref="TOP">
    <div class="top-box">
      <div class="slide">
        <img width="100%" src="/imgs/24165734_m.jpg" alt="" />
      </div>
      <p class="top-left">お問い合わせ<br /></p>
    </div>
    <v-card width="80%" class="mx-auto my-12">
      <table class="table2">
        <tbody>
          <tr>
            <th>
              <p class="ml-2 mr-2">電話でのお問い合わせ</p>
            </th>
            <td>
              <v-row>
                <v-col
                  cols="auto"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="12"
                  xs="12"
                  class="mx-auto text-center"
                >
                  <div>
                    <span style="font-size: 110%"
                      >大宮店<br />
                      <a href="tel:048-662-9300" style="font-size: 110%">
                        TEL:048-662-9300</a
                      ></span
                    ><br />
                    営業時間:10:30-19:30<br />
                    定休日:金曜日<br />
                  </div>
                </v-col>
                <v-col
                  class="mx-auto text-center"
                  cols="auto"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <div>
                    <span style="font-size: 110%"
                      >オンライン専用電話<br />
                      <a href="tel:070-7102-8107">TEL:080-7102-8107</a></span
                    ><br />

                    営業時間:10:00-16:30<br />
                    定休日:土・日・祝<br />
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <th>
              <p class="ml-2 mr-2">LINEでのお問い合わせ</p>
              <br />
            </th>
            <td class="mx-auto text-center">
              <a href="https://lin.ee/8fn7PeL" class="mt-5 mr-5"
                ><img
                  class="mt-5"
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                  height="32"
              /></a>

              <img
                class="mx-auto"
                width="100px"
                src="https://qr-official.line.me/gs/M_002myvqv_GW.png"
              />

              <p>
                お問い合わせの際は、ブランド名、症状など、なるべく詳細をお伝えください。<br />
                写真を添付いただけると、より詳しくご返答が可能です。
              </p>
            </td>
          </tr>
          <tr>
            <th>
              <p class="ml-2 mr-2">メールでのお問い合わせ</p>
            </th>
            <td>
              <div class="mx-auto text-center">
                <a
                  href="mailto:online@time-repair.com?subject=%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body= %0d%0a 
%E3%80%90%E3%81%8A%E5%90%8D%E5%89%8D%E3%80%91%EF%BC%9A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20 様 %0d%0a  %0d%0a
%E3%80%90%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%80%91%EF%BC%9A%20%0d%0a
%E3%83%A2%E3%83%87%E3%83%AB%E7%95%AA%E5%8F%B7%E7%AD%89%E3%82%8F%E3%81%8B%E3%82%8C%E3%81%B0%E8%A8%98%E8%BC%89%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84(%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20)  %0d%0a %0d%0a
%E3%80%90%E7%97%87%E7%8A%B6%E3%80%91%EF%BC%9A%20 %0d%0a  %0d%0a 
%E2%80%BB%E5%8F%AF%E8%83%BD%E3%81%A7%E3%81%82%E3%82%8C%E3%81%B0%E3%81%8A%E6%99%82%E8%A8%88%E3%81%AE%E5%86%99%E7%9C%9F%E3%82%92%E6%B7%BB%E4%BB%98%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82"
                >
                  <span class="text-xl">online@time-repair.com</span> </a
                ><br />
                お問い合わせの際は、ブランド名、症状など、なるべく詳細をお伝えください。<br />
                写真を添付いただけると、より詳しくご返答が可能です。
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return {};
  },

  mixins: [doAxios],

  methods: {},
  mounted() {
    if (this.$route.hash.match(/^#/)) {
      const el = this.$refs[this.$route.hash.slice(1)];
      el.scrollIntoView({ behavior: "smooth" });
    } else {
      const el = this.$refs["TOP"];
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
};
</script>
<style scoped>
.top-box {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  background-color: rgba(19, 53, 128, 0.8);
}

.top-left {
  position: absolute;
  top: 20%;
  left: 10%;
  color: white;
  font-size: 2.5rem;

  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.14em;
  transform: scaleX(0.825);
  transform-origin: left center;
  text-shadow: 4px 5px 4px rgb(28, 72, 168);
}

.slide {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  background: white;
}

.slide img {
  position: absolute;
  top: 20%;
  left: 50%;

  width: calc(100% + 100px);
  opacity: 0;
  animation: slideshow 8s both 1;
}

@keyframes slideshow {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  10% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -40%);
  }
}

.table2 {
  font-family: "Noto Sans JP", "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif;
  font-size: 16px;
  line-height: 1.846;
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: 0.1em;
  *font-size: small;
  width: 100%;
}
.table2 th {
  background-color: rgb(192, 192, 192);
}
.table2 td {
  height: 150px;
}
</style>
