<template>
  <div class="mt-10">
    <!-- 特定商取引法に基づく表示 Section Start ---------------------------------------------------------------->
    <v-card width="80%" class="mx-auto mt-10" flat>
      <h2
        class="section-title wow fadeInUp animated"
        data-wow-delay="0.2s"
        style="
          visibility: visible;
          -webkit-animation-delay: 0.2s;
          -moz-animation-delay: 0.2s;
          animation-delay: 0.2s;
        "
      >
        特定商取引法に基づく表示
      </h2>

      <table>
        <tbody>
          <tr>
            <th>販売事業者</th>
            <td>株式会社 長野商店</td>
          </tr>
          <tr>
            <th>運営統括責任者名</th>
            <td>取締役社長 渡邉尚文</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>
              〒101-0021<br />
              東京都千代田区外神田6丁目15番11号6階<br />
              TEL 03-6803-0531<br />
              FAX 03-6803-0541
            </td>
          </tr>
          <tr>
            <th>修理代金以外の必要料金</th>
            <td></td>
          </tr>
          <tr>
            <th>申込有効期限</th>
            <td>
              最終見積り後10日経ってもご連絡の無い場合は、着払いにてご返送させていただきます。
            </td>
          </tr>
          <tr>
            <th>お支払方法</th>
            <td>Square、クレジットカード</td>
          </tr>
          <tr>
            <th>お支払期限</th>
            <td></td>
          </tr>
          <tr>
            <th>不良品</th>
            <td>万が一修理に不備がございましたら弊社までご連絡下さい。</td>
          </tr>
          <tr>
            <th>その他</th>
            <td>ご利用規約も合わせご確認ください。</td>
          </tr>
        </tbody>
      </table>
    </v-card>
    <!-- 特定商取引法に基づく表示 Section End ---------------------------------------------------------------->

    <div class="mt-16 mb-5 text-center">
      <contract-footer class="mt-15"></contract-footer>
    </div>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
import contractFooter from "@/components/organisms/contractFooter";

export default {
  data() {
    return { notice: [] };
  },
  mixins: [doAxios],

  components: { contractFooter },

  methods: {
    async getNotice() {
      const php = "number_call/getNotice";
      this.notice = await this.doAxios({ php });
    },
  },
  mounted() {
    this.getNotice();
  },
  watch: {},
};
</script>
<style scoped>
.bk {
  background: #4f7594;
  color: #ffffff;
  width: 80%;
  text-align: center;
}
table {
  border: 3px solid #4f7594;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
}
table thead th,
table tbody th,
table tbody td {
  border-bottom: 1px solid #aaa;
  height: 20px;
  padding-left: 20px;
}
table th {
  background-color: #4f7594;
  color: #ffffff;
  width: 30%;
}
table tr {
  height: 50px;
}
</style>
