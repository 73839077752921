<template >
  <div id="HOME" class="mt-10" ref="TOP">
    <v-card width="100%">
      <v-img src="/imgs/ohmiya/web_logo.jpg" height="170px"></v-img>

      <v-row class="mt-3">
        <v-carousel hide-delimiters>
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
          ></v-carousel-item>
        </v-carousel>
      </v-row>

      <v-row justify="center">
        <v-card class="d-box mt-5 mb-10" flat width="90%" height="1400px">
          <div>
            <v-row justify="center" no-gutters>
              <v-img src="/imgs/ohmiya/P1011515-2.jpg" contain></v-img>
            </v-row>

            <v-row justify="center">
              <span class="d-flex justify-center">
                <table class="waiting-table">
                  <thead>
                    <tr>
                      <!--  <th class="waiting-box waiting-font" colspan="2">順番待ちをする</th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <a href="https://lin.ee/1LW6qTQ"
                          ><img
                            src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                            alt="友だち追加"
                            height="36"
                        /></a>
                        <!--    <th class="waiting-th"><a href="https://lin.ee/u3Zbms9"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0"></a>-->
                      </th>
                      <td>
                        公式ＬＩＮＥ登録で時計に関する相談などもお気軽にお受けします！（無料）
                      </td>
                      <!-- <td class="waiting-th"><router-link to="/numberCall"><v-btn color="primary" >メールで順番待ち</v-btn></router-link></td>-->
                    </tr>
                  </tbody>
                </table>
              </span>
            </v-row>
            <v-row justify="center">
              <span class="sub-color-bc border-text" id="ACCESS"
                >営業時間・マップ</span
              >
            </v-row>
            <v-row justify="center" class="mt-5">
              <v-card width="80%" flat>
                <v-row>
                  <v-col cols="auto" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="address-font">
                      〒330-0845<br />
                      埼玉県さいたま市大宮区仲町2-24-2<br />仲町金杉ビル １階<br />
                      旧大宮区役所前 タイガー薬局様となり
                    </div>
                    <br />
                    <div class="time-font tel">
                      <a href="tel:048-662-9300">TEL:048-662-9300</a>
                    </div>

                    <br />
                    <v-row justify="center">
                      <table class="time-table ml-2 mt-2">
                        <thead>
                          <tr>
                            <th class="time">&nbsp;</th>
                            <th class="youbi">月</th>
                            <th class="youbi">火</th>
                            <th class="youbi">水</th>
                            <th class="youbi">木</th>
                            <th class="youbi">金</th>
                            <th class="youbi">土</th>
                            <th class="youbi">日</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="tr-h">
                            <th class="time">10：30-19：30</th>
                            <td class="t-center">〇</td>
                            <td class="t-center">〇</td>
                            <td class="t-center">〇</td>
                            <td class="t-center">〇</td>
                            <td class="t-center">✕</td>
                            <td class="t-center">〇</td>
                            <td class="t-center">〇</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="time-font">
                        営業時間:10:30-19:30 定休日:金曜日
                      </div>
                      <v-col cols="12">
                        <v-row justify="center">
                          <h2>
                            <span v-if="notice">{{ dispNotice() }}</span>
                          </h2>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto" xs="12" sm="12" md="6" lg="6" xl="6">
                    <a href="/imgs/ohmiya/map.jpg" target="_blank"
                      ><v-img
                        src="/imgs/ohmiya/map.jpg"
                        contain
                        width="50%"
                      ></v-img
                    ></a>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </div>
        </v-card>
      </v-row>

      <div class="parallax_content img_bg_02 mt-5 mb-10">
        <v-row justify="center">
          <span class="sub-color-bc border-text">コンセプト</span>
        </v-row>
        <v-row justify="center ">
          <v-card class="d-box mt-5 mb-5" flat min-height="350px">
            <v-card flat class="mt-5 font-s">
              タイムリペアは、都内大手デパートの「時計修理」４店舗を運営する時計修理会社の直営店です。<br />
              「街の時計屋さんが無くて修理をどこに持って行っていいかわからない」「デパートは高い・・・」<br />
              そんな、声を多くいただきます。
              そこで、弊社では一人でも多くのお客様の困ったを解決できるよう、直営の専門店をオープンいたしました。<br />
              デパート品質を低価格でご提供いたします。<br />
              時計修理専門の技術者が直接お客様の相談や電池交換、修理をお受けしております。
              電池交換は最短5分程で完了致します。
              ご相談は無料ですのでお気軽にご相談ください。<br />
              系列のTimeRepair オンラインでは配送での修理受付もしております。<br />
            </v-card>
          </v-card>
        </v-row>
      </div>

      <v-img
        class="d-box"
        v-show="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
        src="/imgs/ohmiya/IMG_0025.jpg"
      ></v-img>
      <div class="parallax_content img_bg_01 d-box mt-5" id="SERVICE">
        <v-row justify="center">
          <span class="sub-color-bc border-text">サービス</span>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-card>
              <v-card-title
                ><v-img
                  height="150px"
                  width="250px"
                  src="/imgs/ohmiya/S__89440282.jpg"
                ></v-img
              ></v-card-title>
              <v-card height="500px" flat class="justify-center">
                <v-row justify="center">
                  <v-card-title class="justify-center bk"
                    >ＢＡＴＴＥＲＹ</v-card-title
                  ></v-row
                >
                <v-row justify="center">
                  <v-card-title class="sub-color">電池交換</v-card-title>
                  <v-btn
                    text
                    class="menu-btn mt-4"
                    @click="to_hash('price', '#BATTERYPRICE')"
                    >PRICE<br />料金表</v-btn
                  >
                </v-row>

                <v-row justify="center" no-gutters>
                  <v-card-title class="font-20"
                    >国産：1,100円～<br />輸入：1,650円～</v-card-title
                  >
                </v-row>

                <v-card-text color="#FFFFFF" class="font-s">
                  電池交換 ”最短5分”<br />
                  基本的に電池交換は店頭その場で行っています。<br />
                  セイコー、シチズン、ダニエルウェリントン、スカーゲン
                  オメガ、カルティエ、グッチ、エルメスなど<br />
                  国産・輸入・ブランド品など基本的にどんな時計でもお受けしております。<br />
                  他店で断られたものもご相談ください。
                </v-card-text>

                <!-- <v-btn color="#EB6E50" dark absolute right bottom>詳細を見る</v-btn>-->
              </v-card>
            </v-card>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-card>
              <v-card-title
                ><v-img
                  height="150px"
                  width="250px"
                  src="/imgs/ohmiya/rolex.jpg"
                ></v-img
              ></v-card-title>
              <v-card height="500px" flat class="justify-center">
                <v-row justify="center">
                  <v-card-title class="justify-center bk"
                    >WATCH REPAIR</v-card-title
                  ></v-row
                >
                <v-row justify="center">
                  <v-card-title class="sub-color">腕時計修理</v-card-title
                  ><v-btn
                    text
                    class="menu-btn mt-4"
                    @click="to_hash('price', '#PRICETOP')"
                    >PRICE<br />料金表</v-btn
                  ></v-row
                >

                <v-row justify="center" no-gutters>
                  <v-card-title class="font-20"
                    >分解掃除：7,700円～</v-card-title
                  >
                </v-row>

                <v-card-text color="#FFFFFF" class="font-s">
                  ロレックス・オメガ・カルティエなどブランド品<br />
                  パテックフィリップ・バセロンコンスタンタンなど高級モデル<br />
                  セイコー・シチズンなど国産時計などなんでもお気軽にご相談ください。<br />
                  自社工房だから出来る、低価格・高品質の修理を
                  デパート価格の２～３割引きの価格でお受けしております。<br />
                  店頭での簡易見積は無料です。お気軽にまずはご相談ください。<br />
                  各種メーカーでの修理もお承り可能です。
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-card>
              <v-card-title
                ><v-img
                  height="150px"
                  width="250px"
                  src="/imgs/ohmiya/clock_bonbon.jpg"
                ></v-img
              ></v-card-title>
              <v-card height="500px" flat class="justify-center">
                <v-row justify="center">
                  <v-card-title class="justify-center bk"
                    >CLOCK REPAIR</v-card-title
                  ></v-row
                >
                <v-row justify="center">
                  <v-card-title class="sub-color">クロック修理</v-card-title>
                  <v-btn
                    text
                    class="menu-btn mt-4"
                    @click="to_hash('price', '#CLOCKPRICE')"
                    >PRICE<br />料金表</v-btn
                  >
                </v-row>

                <v-row justify="center" no-gutters>
                  <v-card-title class="font-20"
                    >分解掃除：16,500円～</v-card-title
                  >
                </v-row>

                <v-card-text color="#FFFFFF" class="item-texts font-s">
                  置時計、掛け時計などのクロックの修理、受けるところが少なくなっています。<br />
                  タイムリペアでは専任の技術者が修理をお承りしております。<br />

                  ボンボン時計はもちろん、ホールクロックの出張修理、
                  目覚し時計や、キャリッジクロック等、一度ご相談ください。
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-row justify="center">
        <v-col xs="12" sm="12" md="4" lg="4" xl="4">
          <v-card flat height="350px" min-width="350px" class="mt-5">
            <v-card-title
              ><v-img height="300px" src="/imgs/ohmiya/gredeer.jpg"></v-img
            ></v-card-title>
          </v-card>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="4" xl="4">
          <v-card flat height="350px" min-width="350px" class="mt-8">
            <v-row justify="center"
              ><v-card-title class="justify-center bk mt-5"
                >ＳＴＲＡＰ</v-card-title
              ></v-row
            >
            <v-row justify="center">
              <a
                href="https://www.hirschjapan.com/"
                target="_blank"
                class="mt-2"
              >
                <v-img
                  contain
                  max-height="20px"
                  class="mt-3"
                  src="/imgs/ohmiya/hirsch.jpg"
                ></v-img
              ></a>
              <a href="https://www.bambi.jp/" target="_blank" class="mt-2">
                <v-img
                  contain
                  max-height="30px"
                  class="mt-3"
                  src="/imgs/ohmiya/bambi_logo.png"
                ></v-img
              ></a>
              <a
                href="https://www.cassis-watch.jp/"
                target="_blank"
                class="mt-2"
              >
                <v-img
                  contain
                  max-height="30px"
                  class="mt-3"
                  src="/imgs/ohmiya/cassis_M.jpg"
                ></v-img
              ></a>
              <a href="https://www.morellato.jp/" target="_blank">
                <v-img
                  contain
                  max-height="40px"
                  class="mt-3"
                  src="/imgs/ohmiya/morellato_new_M.jpg"
                ></v-img
              ></a>
            </v-row>
          </v-card>
        </v-col>
        <v-col xs="12" sm="12" md="4" lg="4" xl="4">
          <v-card
            flat
            height="350px"
            min-width="350px"
            class="mt-5 sub-color-bc"
          >
            <v-card-text class="item-texts">
              <span class="text-w">
                バンビ・カシス・モレラート・ヒルシュなど
                有名ブランドのバンドを各種取り揃えてお待ちしております。
              </span>
              <v-img src="/imgs/ohmiya/P1011638.jpg"></v-img>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card flat class="d-box mt-10" height="700px">
        <v-row justify="center">
          <span class="sub-color-bc border-text">オーバーホール</span>
        </v-row>

        <v-row justify="center">
          <v-card width="90%" flat>
            <v-img src="/imgs/ohmiya/OVH.jpg" contain></v-img>
            <v-row justify="center">
              <v-card width="90%" class="mt-5" flat>
                <table class="ovh">
                  <tr>
                    <td class="sub-color-bc text-w ovh-title">
                      <div>時計の修理（オーバーホール）について</div>
                    </td>
                    <td class="bc-w text-w"></td>
                  </tr>
                  <tr>
                    <td colspan="2" class="ovh-main">
                      <div>
                        お客様からの質問で多いのが、止まってしまったが部分的に治せないか？ということです。<br />
                        時計が止まってしまう原因は色々ありますが多くは油の劣化、衝撃等による部品の破損、湿気などによる歯車のサビなどです。
                        不具合の原因を調べるのに、複雑に重なり合った歯車を一つ一つ調べていかなければいけません。<br />
                        その為には、多くの部品を分解する必要があります。一旦分解した部品は、油が流れてしまったり、調整をするため、結果的に部分修理が難しいことが多いです。
                        一時的に部分的な修理で治ったとしても、いつ不具合が出るかわかりません。<br />
                        安心してお使いいただくために、オーバーホールをおすすめいたします。
                      </div>
                    </td>
                  </tr>
                </table>
              </v-card>
            </v-row>
          </v-card>
        </v-row>
      </v-card>

      <v-row justify="center">
        <v-card flat width="95%">
          <div>
            <v-row justify="center" class="pb-5"><brand-s></brand-s></v-row>
          </div>
        </v-card>
      </v-row>

      <v-card class="mt-5 mb-10" flat id="QA">
        <v-row justify="center">
          <span class="sub-color-bc border-text">よくある質問</span>
        </v-row>
        <qand-a></qand-a>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
//import numberCount from "@/components/organisms/numberCount";
import brandS from "@/components/pages/brands";
import qandA from "@/components/pages/QandA";

export default {
  data() {
    return {
      items: [
        {
          src: "/imgs/ohmiya/P1011581.jpg",
        },
        {
          src: "/imgs/ohmiya/P1011638.jpg",
        },
        {
          src: "/imgs/ohmiya/P1011515-2.jpg",
        },
        {
          src: "/imgs/ohmiya/imgs/P1011588.jpg",
        },
        {
          src: "/imgs/ohmiya/imgs/P1011541.jpg",
        },
        {
          src: "/imgs/ohmiya/P1011604.JPG",
        },
      ],

      show: false,
      tab: "",
      waiting_count: 0,
      twitterHeight: "400",
      objPosY: 0,
      open_time: false,
      notice: "",
    };
  },
  components: {
    //  numberCount,
    qandA,
    brandS,
  },

  mixins: [doAxios],

  methods: {
    async getOrderCount() {
      //   this.$store.dispatch('authIsProgress', true)
      const php = "get_waiting/getWaitingCount";
      const result = await this.doAxios({ php });

      if (result == "closed") {
        this.open_time = false;
      } else {
        this.open_time = true;
      }
      if (result[0].waiting_count == "" || result[0].waiting_count == null) {
        this.waiting_count = 0;
      } else {
        this.waiting_count = result[0].waiting_count;
      }
      //     this.$store.dispatch('authIsProgress', false)
    },
    dispNotice() {
      if (this.notice == null || this.notice == "") {
        return;
      } else {
        const res = this.notice.filter((value) => value.notice_type == 1);
        if (res == null || res == "") {
          return;
        } else {
          return res[0].notice;
        }
      }
    },
    async getNotice() {
      const php = "number_call/getNotice";
      this.notice = await this.doAxios({ php });
    },
    to_hash(path, Hash) {
      if (path == this.$route.name) {
        this.$router.push({ hash: "" });
        this.$router.push({ hash: Hash });
      } else {
        this.$router.push({ name: path, hash: Hash });
      }
    },
  },

  watch: {
    $route: function (n) {
      if (n.hash.match(/^#/)) {
        document.getElementById(n.hash.replace(/^#/, "")).scrollIntoView();
      }
    },
  },
  mounted() {
    if (this.$route.hash.match(/^#/)) {
      const el = this.$refs[this.$route.hash.slice(1)];
      el.scrollIntoView({ behavior: "smooth" });
    } else {
      const el = this.$refs["TOP"];
      el.scrollIntoView({ behavior: "smooth" });
    }
    //  this.getOrderCount();
    this.getNotice();
  },
};
</script>
<style scoped>
.title_bk {
  background-image: url(https://time-repair.jp/ohmiya/imgs/back_watch.jpg);
  background-size: 100% auto;
}
.bc-img {
  background-image: url(https://time-repair.jp/ohmiya/imgs/1-100.jpg);
  background-repeat: repeat;
  border-radius: 50px 0 50px 0;
}
.text-c {
  text-align: center;
}
.font-20 {
  font-size: 20px;
}
.bk {
  background: #4f7594;
  color: #ffffff;
  width: 80%;
  text-align: center;
}
.bc-w {
  background: #ffffff;
}

.text-w {
  color: #ffffff;
  font-size: 16px;
}
.font-50 {
  font-size: 50px;
}
.band {
  height: 500px;
  width: 90%;
  margin-top: 10px;
}

.waiting-table {
  border: 1px solid #f0f0f0;
  margin-top: 20px;
  box-shadow: 5px 5px 0px 0 rgb(163, 163, 163);
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 4px;
}

.waiting-table td {
  background: #ffffff;
}

.border-text {
  width: 50%;
  margin-top: 10px;
  padding: 10px;
  color: #fff;
  background: #d28a94;
  transition: all 0.4s;
  transform: skewX(-16deg);
  text-align: center;
  font-size: 1.4em;
}
.border {
  margin-top: 50px;
  border-width: 3px;
  width: 1200px;
  text-align: center;
  color: #d28a94;
}

.menu-btn {
  border-right: thick solid #d28a94;
  border-left: thick solid #d28a94;
  border-width: 1px;

  width: 130px;
  text-align: center;
}

.time-font {
  font-size: 20px;
  margin-left: 20px;
  text-align: center;
}
.address-font {
  font-size: 16px;
  margin-left: 10px;
  text-align: center;
}
.ovh {
  border: none;
  border-collapse: separate;
  border-spacing: 0;
}
.ovh-main {
  border: 1px solid #d28a94;
  border-collapse: separate;
  border-spacing: 0;
}
.ovh-title {
  width: 50%;
  border: none;
  padding: 5px;
}
table {
  border: 3px solid #4f7594;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
}
table thead th,
table tbody th,
table tbody td {
  border-bottom: 1px solid #aaa;
}
table thead th {
  background-color: #4f7594;
  color: #ffffff;
}
table tbody th {
  background-color: #eee;
}
table thead th + th,
table tbody td {
  border-left: 1px solid #aaa;
}
table tbody tr:last-child th,
table tbody tr:last-child td {
  border-bottom: none;
}
table thead tr:first-child th:first-child {
  border-radius: 5px 0 0 0;
}
table thead tr:first-child th:last-child {
  border-radius: 0 5px 0 0;
}
table tbody tr:last-child th:first-child {
  border-radius: 0 0 0 5px;
}
table tbody tr:last-child th:last-child {
  border-radius: 0 0 5px 0;
}
.youbi {
  padding: 7px;
}
.t-center {
  text-align: center;
}
.tr-h {
  height: 50px;
}
.time {
  width: 90px;
  font-size: 12px;
}
.parallax_content {
  height: 100%;

  padding-left: 5%;
  padding-right: 5%;

  justify-content: center;
  align-items: center;

  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.img_bg_01 {
  background-image: url(https://time-repair.jp/ohmiya/imgs/IMG_0025.jpg);
}

.img_bg_02 {
  background-image: url(https://time-repair.jp/ohmiya/imgs/P1011555.jpg);
}
.tel {
  color: black;
  font-size: 28px;
  font-family: "KleeOne-Regular";
}
.font-s {
  color: black;
  font-size: 1.2em;
  font-family: "Open Sans";
}
</style>
