import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        Id: "",
        Token: "",
        LoginToken: "",
        StoreID:"",
        Role:"",
        Menu:false,
        MessageChk:"",
        isProgress:false
    },
    getters: {
        getUserId: state => {
          return state.Id
        },
        getStoreId: state => {
            return state.StoreID
          }
      },

    mutations: {
        setToken(state, usertoken) {
            state.Token = usertoken;
        },
        setId(state, userid) {
            state.Id = userid
        },
        setLoginToken(state, logintoken) {
            state.LoginToken = logintoken;
        },
        setRole(state, Role) {
            state.Role = Role;
        },
        setStore(state, storeId) {
            state.StoreID = storeId;
        },
        setMenu(state, menu) {
            state.Menu = menu;
        },
        setMessageCHK(state, messagechk) {
            state.MessageChk= messagechk;
        },
        setIsProgress(state, isProgress) {
            state.isProgress= isProgress;
        }
    },
    actions: {
        authToken(context, usertoken) {
            context.commit('setToken', usertoken);
        },
        authId(context, userid) {
            context.commit('setId', userid);
        },
        authLoginToken(context, logintoken) {
            context.commit('setLoginToken', logintoken);
        },
        authRole(context, role) {
            context.commit('setRole', role);
        },
        authStore(context, storeId) {
            context.commit('setStore', storeId);
        },
        authMenu(context, menu) {
            context.commit('setMenu',menu);
        },
        authMessageCHK(context,messagechk) {
            context.commit('setMessageCHK', messagechk);
        },
        authIsProgress(context,isProgress) {
            context.commit('setIsProgress', isProgress);
        }
    },
    strict: true,
    plugins: [createPersistedState({
        key: 'TApp',
        storage: window.sessionStorage
    })]
})

export default store