<template>
  <div class="mt-10">
    <v-card width="80%" class="mx-auto mt-10" flat>
      <!-- 利用規約 Section Start ---------------------------------------------------------------->

      <div class="section-title-header text-center">
        <h2
          class="section-title wow fadeInUp animated"
          data-wow-delay="0.2s"
          style="
            visibility: visible;
            -webkit-animation-delay: 0.2s;
            -moz-animation-delay: 0.2s;
            animation-delay: 0.2s;
          "
        >
          ご利用規約
        </h2>
        <p
          class="wow fadeInDown about-text animated"
          style="visibility: visible"
        >
          当社＝株式会社長野商店の時計修理、時計修理見積もりにお申し込み、<br />
          送付キットにお申し込みの場合は以下の規約に同意したものとさせて頂きます。
        </p>
      </div>

      <div class="termscontents">
        <p class="about"><b>■修理保証について</b></p>
        <p>
          ・修理完了日（原則として当社から時計発送日。修理完了後、暫く当社で保管する場合は修理完了連絡日）から一年間、時計内装部の修理箇所に同一の不具合が生じた場合、無償にて再修理をさせていただきます。
        </p>
        <p>
          ・アンティーク時計（概ね２０年以上前に製造された時計、メーカー側で部品又は時計の製造が中止になった時計及び当社がアンティークと判断したものをいいます）については、1年間の保証となります。
        </p>
        <p>
          ・時計の構造や状態（サビ、腐食、損壊等）、パーツ入手事情により、保証が短くなったり、保証がつけられない場合があります。
        </p>
        <p>
          ・修理後、納入した時計についての不具合が、以下に例示するような感覚的なものでかつ当社の規定において測定器で測定しても異常が認められない場合、修理保証の対象外となります。<br />
          （1）以前となんとなく違う。<br />
          （2）音が大きくなったような気がする。<br />
          （3）リューズ・プッシュボタンの感覚がおかしい。<br />
          （4）見た目が以前と違う。<br />
          （5）同じ機種で比較するとこっちの方がおかしい感じがする。
        </p>
        <p>
          ・以下の場合には、保証期間内であっても有償修理となります。<br />
          （1）落下・衝突等の衝撃、浸水、磁気入り、火災や地震等天変地異による故障・破損<br />
          （2）外装部品（リューズ・プッシュボタン・針・文字盤含む）及びゼンマイについての切断・損傷等の不具合<br />
          （3）当社以外で裏蓋を開けて時計内部を確認した場合<br />
          （4）当社以外での修理又は改造による故障・不具合<br />
          （5）修理箇所とは別の箇所を原因とする故障<br />
          （6）当社で発行する修理明細書のご提示がなく、当社による修理が確認できない場合<br />
          （7）配送時に生じた故障・破損<br />
          （8）不適切な取り扱いによって生じた故障・破損
        </p>
        <p>
          ・保証あるなしにかかわらず、再修理の場合、往復の送料、梱包材や集荷の手配はお客様側でご負担ください。
        </p>
        <p>
          ・保証内での再修理は、保証期間中に当社に時計が到着した場合のみ有効です。
        </p>
        <p>・修理保証はお申込者ご本人様のみ有効となります。</p>
        <hr />
        <p class="about"><b>■重故障状態の時計の修理について</b></p>
        <p>
          ・アンティーク、落下や冠水による故障、部品の著しい損傷、電池液漏れ、基盤腐食等重故障状態の時計については、修理しても動かないままである可能性及び動いたとしても数週間で止まる可能性があるため、修理保証が短くなったり、修理保証をつけられない場合があります。
        </p>
        <p>
          ・修理によって時計が動くようになったものの、再び動かなくなったときは、再修理を致します。
        </p>
        <p>・重故障のために保証がない場合、再修理は有料となります。</p>
        <p>
          ・再修理によって時計が動かないままであるときには、再修理の修理代金は発生しません。この場合、既にいただいている初回の修理の修理代金は返還は出来かねます。
        </p>
        <p>
          ・見積もり依頼時に落下や冠水、電池液漏れ等重故障に関する事実のご報告がなかった場合、当初のお見積りを破棄し、新たに保証なしでのお見積りをさせていただく場合があります。この場合において、キャンセルすることはできません。
        </p>
        <p></p>
        <hr />
        <p class="about"><b>■補償について</b></p>
        <p>
          ・当社の過失によって破損・不具合が生じた場合は、原則として修理で対応いたします。
        </p>
        <p>
          ・前項の場合で、修理をしても時計が正常に動作しないときや、修理対応できないときは、減価償却後の残存価格、又は現在販売されている同等商品の価格のいずれか低い方の価格を基準とし、その補償価格は上限を10万円までとします。
        </p>
        <p>
          ・ただし、運送中に生じた破損については、運送会社の定める補償対象になる場合に限り、運送会社の定める補償の上限額までの対応と致します。
        </p>
        <hr />
        <p class="about"><b>■修理申込みについて</b></p>
        <p>
          ・修理においては最善を尽くしますが、新品同様に復元することは不可能であることをご理解の上お申し込みください。
        </p>
        <p>
          ・修理にあたっては、時計の誤差を日差±15秒以内に収めておりますが、修理依頼時に当該日差があった場合、いかなる修理をしても日差を±15秒以内にできない場合があります。
        </p>
        <p>
          ・見積もりにおいて修理可能と判断した場合であっても、修理進行上どうしても修理ができない場合があります。この場合、代金はいただきません。
        </p>
        <p>・修理の進行によっては、別途費用が発生する場合があります。</p>
        <hr />
        <p class="about"><b>■お見積りについて</b></p>
        <p>
          ・お見積りのために修理工房にて時計を分解させていただき、その時点における故障個所をもって修理対象とさせていただきます。
        </p>
        <p>
          ・時計が当社に到着した後、７～１４営業日前後でお見積りをさせていただきます。ただし、部品の調達具合、時計の内部の状態、当社の混雑状況等によっては遅れる場合がございます。
        </p>
        <p>
          ・お見積りの有効期間は、正式なお見積りメールを発信した日から１か月間となります。
        </p>
        <p>
          ・お見積もりは慎重に行いますが、完全に明記できない場合がございます。
        </p>
        <p>
          ・修理作業中に新たな修理個所が見つかった場合、お見積りした代金及び納期を変更させていただくことがあります。この場合、キャンセル料はいただきません。
        </p>
        <hr />
        <p class="about"><b>■キャンセルについて</b></p>
        <p>
          ・当社よりお見積りを提示したのち、修理進行の回答があるまでは、キャンセルすることができます。この場合、キャンセル料はいただきません。
        </p>
        <p>
          ・キャンセルされた場合、お見積りのために分解した時計を組み立てるのに３～７営業日かかります。時計を組み立て後、ご返却致します。
        </p>
        <p>
          ・お見積りのために時計を一度分解しております。キャンセル後、必ずしも元の状態でお返しすることはできません。また、郵送中も含め、お預かりの時計の状態が変わる場合があります。
        </p>
        <p>
          ・修理進行の回答があったのち、お客様のご都合によりキャンセルされる場合には、修理代相当額額を請求させていただきます。
        </p>
        <hr />
        <p class="about"><b>■修理不能品について</b></p>
        <p>
          ・古い時計や製造中止となった時計で、部品調達が困難であり部品の新規製作対応のできないもの、その他当社が修理できないと判断したものは、修理不能のためお断りすることがあります。<br />
          （アフターダイヤ時計、シェル（真珠貝）文字盤時計、デジタル時計、ソーラー時計、電波式時計、充電式自動巻き時計、特殊素材時計、特殊ムーブメント時計などは修理できません。）
        </p>
        <p>
          ・お見積りのために時計を一度分解や精査をしております。時計の状態により修理不可のご連絡後、必ずしも元の状態でお返しすることはできません。
        </p>
        <hr />
        <p class="about"><b>■納品について</b></p>
        <p>
          ・原則として、修理依頼後約一か月を納期として提示させていただいております。
        </p>
        <p>
          ・ただし、アンティーク時計や、混雑時、部品の調達に時間がかかる場合など、３か月以上の納期をいただく場合があります。
        </p>
        <p>
          ・修理において交換した部品は、下取り品、メーカー回収品、ボタン電池などを除き原則としてお客様にお返しします。
        </p>
        <p>
          ・修理完了品は、修理代金を代金引換にてお送りいたします（代引き手数料はお客様の負担となります）。
        </p>
        <p>
          ・修理完了、修理不可及びキャンセルの連絡を発した日から半年以内にお引き取りいただけない場合、又は連絡がとれない場合には、当社でお預かりしている時計及び物品の所有権を放棄したものとします。
        </p>
        <hr />
        <p class="about"><b>■送料について</b></p>
        <p>
          ・「送付キット」をご依頼される場合、送料は当社にて負担いたします。（国内のみ）
        </p>
        <p>
          ・「送付キット」は原則としてオーバーホールを希望される方のみにお送りしております。
        </p>
        <p>・「送付キット」は、腕時計・懐中時計専用です。</p>
        <p>
          ・腕時計及び懐中時計以外の時計（特殊時計、その他当社が送付キットの適用外と判断した物）については、お客様の送料負担においてお送りください。
        </p>
        <p>・運送保険をかけられる場合はお客様ご負担となります。</p>
        <p>
          ・以下の場合については、お預かりした時計を返送するための送料については、お客様にご負担いただき、着払いにて発送いたします。<br />
          （1）キャンセルの場合<br />
          （2）修理不能品の場合
        </p>
        <p>
          ・以下の場合については、往復の送料・梱包費用を請求いたします。<br />
          （1）電池交換のみ、外装部分の修理のみ、部品交換のみの修理を依頼される場合<br />
          （2）再修理の場合
        </p>
        <p>
          ・以下の場合については、送付キット代、送料及び事務手数料として2,000円を請求させていただき、代金引換にて返送いたします。別途代引き手数料もご負担いただきます。<br />
          （1）コピー品や模造品など、当社で正規品と認められない時計をお送りいただいた場合<br />
          （2）当社が悪質だと判断した場合<br />
          （3）当社からの連絡に対して一か月を経過してもご連絡をいただけない場合<br />
          （4）当社から見積もりを出す前にお客様都合によりキャンセルされた場合
        </p>
        <hr />
        <p class="about"><b>■免責事項</b></p>
        <p>
          ・当社及び関連会社は、次の事由による修理品の滅失、毀損、遅延による損害については、損害賠償の責任を負いません。<br />
          （1）社会的騒擾その他の事変又は強盗等第三者による犯罪行為<br />
          （2）不可抗力による火災<br />
          （3）予見できない異常な交通障害<br />
          （4）地震、津波、高潮、大水、暴風雨、地すべり、山崩れその他の天災<br />
          （5）法令又は公権力の発動による運送の差止め、開封、没収、差押え又は第三者への引渡し<br />
          （6）運送会社の配送時における事故等(当社と関連会社間を含むすべての配送時)<br />
          （7）その他当社及び関連会社に過失がない場合
        </p>
        <p>
          ・当社及び当社関連会社は時計到着後、撮影をしております。また、見積もりにはお預かり時の状態を記載させて頂いておりますが、完全に記載できない場合がございます。当社はいかなる場合であれ、時計到着時の時計の状態（外装部も含む）に一切の責任を負いません。
        </p>
        <p>
          ・お送りいただいた時計本体及び部品等については、当社に送付キット又は梱包物が到達し、当社が内容物を確認した時点を基準としてお預かりいたします。
        </p>
        <p>
          ・水入り、重故障、経年劣化した時計、損傷した時計、特殊素材の時計、特殊加工、特殊コーティングの時計（メッキ、PVD加工含む）、特殊素材バンド（ウレタンなど）、リューズ・チューブのねじ込み、リューズ関連、プッシュボタン、エスケープバルブ、文字盤、針、ガラス・風防、装飾ダイヤ、装飾品などの外装部、付属品が、当社到着後、適切な取り扱いをしていたとしても、確認中や修理中に劣化が進み、部品が外れたり、損傷が進行する場合があります。当社及び関連会社は、返却後も含め、いかなる場合であっても劣化や部品外れ、または損傷の進行等による損害について、一切の責任を負いません。（修理不可・キャンセルの場合も含む）
        </p>
        <p>
          ・時計の郵送中やお預かり中に外れた部品が動くことにより、時計の内装部、外装部が損傷することあります。外れた部品による損傷について、当社及び関連会社は、一切の責任を負いません。
        </p>
        <p>
          ・当社及び関連会社は、修理不可又はお客様によるキャンセルがあった場合、お預かりした時計を元の状態に戻すことについて、一切の責任を負いません。
        </p>
        <p>
          ・当社での修理は原則として純正部品を使用しますが、純正部品を使用したか純正以外の部品を使用したかにかかわらず、当社での修理後、メーカー修理を依頼しても修理を受け付けてもらえなくなる場合があります。その場合、当社及び関連会社は、メーカー修理を受けられなくなったことによる損害について、一切の責任を負いません。
        </p>
        <p>
          ・当社及び関連会社は、外装仕上げ、ライトポリッシュに関して、一切の責任を負いません。
        </p>
        <p>
          ・オーバーホール後、当社及び関連会社の精度検査の基準は日差±15秒となります。クロノメーター等であっても日差±15秒です。修理依頼時に時計の日差が±15秒以上あった場合において、いかなる修理をしても日差を±15秒に以内に収められない場合があります。その場合、当社及び関連会社は、一切の責任を負いません。
        </p>
        <p>
          ・修理したかどうかに関わらず、当社から時計を返却後、時計を譲渡できなかったり、譲渡価格に変動があったりする場合があります。譲渡に関するいかなる損害についても、当社及び関連会社は一切の責任を負いません。
        </p>
        <p>
          ・お客様と当社の連絡は、メールによってさせていただきます。当社及び関連会社は、メールの送受信不良、連絡の行き違い、及びその他の伝達手段（電話などの口頭での見積もり及び説明を含む）を用いたことによる損害について、一切の責任を負いません。
        </p>
        <p>
          ・当社からお客様に対する連絡の遅れを理由として生じるいかなる損害についても、当社及び関連会社は、一切の責任を負いません。
        </p>
        <p>
          ・当社および関連会社は、本修理規約に定める修理保証及び補償に関して定めているところのほか、無償で修理すべき責任を負いません。
        </p>
        <p>
          ・当社に時計以外のものを送られた場合（梱包物やその他時計修理に関係がないもの）、当社判断で処分させていただく場合があります。その場合、当社及び関連会社は一切の責任を負いません。
        </p>
        <p>
          ・送付キット（初期不良等含む）の利用において生じるいかなる損害について、当社及び関連会社は、一切の責任を負いません。
        </p>
        <hr />
        <p class="about"><b>■その他注意事項</b></p>
        <p>
          ・確認または作業にあたっては細心の注意を払っておりますが、時計部品の経年劣化のため、作業中にやむを得ず時計に傷、破損が生じてしまう場合がございます。予めご了承ください。
        </p>
        <p>
          ・作業中にやむを得ず時計に傷、破損が生じた場合、時計の素材や部品の入手状況によっては対応できない場合がございます。予めご了承下さい。
        </p>
        <p>
          ・外装仕上げ及びライトポリッシュは、ケース及びバンド部を可能な範囲で研磨するもので、完全に傷を取り、凹みをなくし、つやを出し、きれいになることを保証するものではございません。ごく稀に鋳造欠陥が出る場合もあります。予めご了承ください。
        </p>
        <p>
          ・当社のオーバーホールは、３気圧まで無料の防水検査をしております。
        </p>
        <p>
          ・時計は防水であっても基本的に汗、水にはお気をつけ下さい。修理後、リューズの締め忘れや、当社の防水検査以上の水圧（無料の場合は３気圧まで）がかかると浸水する可能性があります。経年劣化により、防水性が低下しているケースもございます。防水検査後、防水エラーが出る場合、ご報告は致しますが、いかなる場合であれ、浸水に関しては保証対象外で有償修理になりますのでご注意ください。
        </p>
        <p>
          ・当社はメーカー修理よりも安価であることを保証するものではありません。
        </p>
        <p>
          ・ご連絡は順次メールにて対応させていただきますが（再修理があった場合も含む）、混雑時や連休明けなどは遅れる場合があります。また、納期や時計の返却に関しても、混雑時や諸事情により遅れる場合があります。予めご了承ください。
        </p>
        <p>
          ・当社は保険を適用される方の修理は控えさせて頂いております。保険を適用されるとわかった場合、修理不可として着払いにてご返却させていただきますので予めご了承下さい。また、当社は保険会社等、お客様以外の方とのやり取りは一切行いませんので予めご了承下さい。
        </p>
        <p>
          ・修理完了後、点検、検査・ランニングテストをしてから時計をお送りしておりますが、ごくまれに郵送等が原因で、到着時やしばらくしてから時計が動かない、調子がおかしいなど、不具合が出る場合がございます。その際は点検・再修理をさせていただきます。
        </p>
        <p>
          ・当社にお申込みをされた時点で当社規定の検査基準に同意したものとみなします。修理前後に関わらず、時計の機能、精度、状態、納期等について神経質な方は予めお申込みをお控えください。<br />
          当社及び関連会社はお客様と対等な立場で、当社の最善の修理及びご対応をさせていただきます。いかなる場合であっても、本修理規約に定めのない値下げ、賠償、再修理その他一切の要求に応じることはありません。<br />
          特に悪質な場合には、速やかに当社顧問弁護士と相談の上、しかるべき措置を取らせていただきます。
        </p>
        <p>
          ・代金引換の場合、代金引換の際に手渡される受領書が領収書となります。当社印の入った領収書が必要な場合は、代金引換の受領書と引換で承りますので、その際は別途お問い合わせください。
        </p>
        <p>
          ・当社の商品の申込・入札･落札･購入･プレゼント応募をされた方に､当社からキャンペーンや広告メール等をお送りすることがあります｡
        </p>
        <p>
          ・当販売サイト、メールやメールマガジン中の保証、修理規約、限定事項の解釈については当社に判断権があり、当社が随時変更することができるものとします。
        </p>
        <p>
          ・販売方法、販売手法、手数料などの決定・変更にまつわる権利は全て当社にあり、いつでも変更できるものとします。
        </p>
      </div>
    </v-card>
    <!-- 利用規約 Section End ---------------------------------------------------------------->
    <div class="mt-16 mb-5 text-center">
      <contract-footer class="mt-15"></contract-footer>
    </div>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
import contractFooter from "@/components/organisms/contractFooter";

export default {
  data() {
    return { notice: [] };
  },
  mixins: [doAxios],

  components: { contractFooter },

  methods: {
    async getNotice() {
      const php = "number_call/getNotice";
      this.notice = await this.doAxios({ php });
    },
  },
  mounted() {
    this.getNotice();
  },
  watch: {},
};
</script>
<style scoped>
.bk {
  background: #4f7594;
  color: #ffffff;
  width: 80%;
  text-align: center;
}
table {
  border: 3px solid #4f7594;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
}
table thead th,
table tbody th,
table tbody td {
  border-bottom: 1px solid #aaa;
  height: 20px;
  padding-left: 20px;
}
table th {
  background-color: #4f7594;
  color: #ffffff;
  width: 30%;
}
table tr {
  height: 50px;
}
</style>
