<template>
  <div ref="PRICETOP">
    <div>
      <v-row justify="center" class="pb-5"><page-footer></page-footer></v-row>
    </div>

    <v-row class="boder3"> </v-row>
    <v-row justify="center" class="price-container">
      <v-col cols="8">
        <v-card class="mt-12 mb-8" flat>
          <v-btn
            class="ml-2 mr-2"
            color="primary"
            large
            @click="scrollToAnchorPoint('BATTERYPRICE')"
            >【電池交換料金表】</v-btn
          >
          <v-btn
            class="ml-2 mr-2"
            color="primary"
            large
            @click="scrollToAnchorPoint('CLOCKPRICE')"
            >【クロック分解掃除】</v-btn
          >
          <v-btn
            class="ml-2 mr-2"
            color="primary"
            large
            @click="scrollToAnchorPoint('BRANDS')"
            >【ブランド別実績】</v-btn
          >
          <v-card flat>
            <v-card-title class="price-title"> WATCH </v-card-title>
          </v-card>
          <v-card-subtitle class="price-title">
            分解掃除基本料金</v-card-subtitle
          >
          <v-row>
            <v-col cols="auto">
              <v-chip-group column color="primary">
                <v-chip
                  small
                  v-for="intial in intials"
                  :key="intial"
                  @click="getIntial(intial)"
                >
                  <span>{{ intial }}</span>
                </v-chip>
              </v-chip-group>
              <v-text-field
                label="ブランド名"
                persistent-hint
                v-model="search_text"
                full-width
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card class="watch_price mt-5" flat height="400px" width="800px">
            <table class="price mt-5">
              <tr>
                <th class="th_brand">ブランド名</th>
                <th class="th_price">クォーツ</th>
                <th class="th_price">クォーツ<br />クロノ</th>
                <th class="th_price">手巻き</th>
                <th class="th_price">自動巻き</th>
                <th class="th_price">メカクロノ</th>
              </tr>
              <tr v-for="(item, i) in prices" :key="i">
                <td class="td_brand">{{ item.brand_name_jp }}</td>
                <td class="td_price">{{ addComma(item.quartz) }}</td>
                <td class="td_price">{{ addComma(item.quartz_chrono) }}</td>
                <td class="td_price">{{ addComma(item.meca) }}</td>
                <td class="td_price">{{ addComma(item.auto) }}</td>
                <td class="td_price">{{ addComma(item.meca_chrono) }}</td>
              </tr>
            </table>
          </v-card>
        </v-card>
        <v-row justify="end">
          <v-card class="font-12 mr-12 mt-5" flat>
            価格はすべて税込です。
            モデルにより基本料金が変更となる場合もございます。
            ※リストに無いブランドはお問い合わせください。
          </v-card>
        </v-row>
        <div ref="BATTERYPRICE"></div>
        <v-row class="boder3 mt-12"></v-row>
        <div>
          <v-row class="boder3 mt-12 mb-8">
            <v-card class="mt-5" flat>
              <v-card-subtitle class="price-title">
                電池交換基本料金</v-card-subtitle
              >
              <v-row>
                <v-col cols="auto">
                  <v-chip-group column color="primary">
                    <v-chip
                      small
                      v-for="intial in intials"
                      :key="intial"
                      @click="getIntial(intial)"
                    >
                      <span>{{ intial }}</span>
                    </v-chip>
                  </v-chip-group>
                  <v-text-field
                    label="ブランド名"
                    persistent-hint
                    v-model="search_text"
                    full-width
                    prepend-inner-icon="mdi-magnify"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card
                class="watch_price mt-8"
                flat
                height="400px"
                width="400px"
              >
                <table class="price mt-5">
                  <tr>
                    <th class="th_brand">ブランド名</th>

                    <th class="th_price">電池交換</th>
                  </tr>
                  <tr v-for="(item, i) in prices" :key="i">
                    <td class="td_brand">{{ item.brand_name_jp }}</td>
                    <td class="td_price">{{ addComma(item.battery) }}</td>
                  </tr>
                </table>
              </v-card>
            </v-card>
          </v-row>
        </div>
        <v-row justify="end">
          <v-card class="font-12 mr-12 mt-5" flat>
            ※リストに無いブランドはお問い合わせください。※価格はすべて税込です。
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <div ref="CLOCKPRICE">
      <v-row justify="center" class="price-container">
        <v-col cols="8">
          <v-row class="boder3 mt-12"></v-row>
          <v-row justify="center">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat>
                <v-card-title class="price-title"> Clock </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-card-title> 納期：1カ月～</v-card-title>
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center" class="price-items boder1">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
              <v-card flat>
                <v-card-title> ホールクロック </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-row
                  ><v-col cols="6">3チャイム/夜間停止/複雑 </v-col
                  ><v-col cols="6">77,000～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6"> チャイム（15分打ち）</v-col
                  ><v-col cols="6"> 60,500～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6"> 時打ちのみ</v-col
                  ><v-col cols="6">44,000～</v-col></v-row
                >
                別途出張料金がかかります。
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center" class="price-items boder1">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
              <v-card flat>
                <v-card-title> 置時計/掛時計（柱時計） </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-row
                  ><v-col cols="6">曲切替え/夜間停止/複雑 </v-col
                  ><v-col cols="6">66,000～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6"> チャイム（15分打ち）</v-col
                  ><v-col cols="6"> 55,000～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6"> 時打ちのみ（ボンボン）</v-col
                  ><v-col cols="6">24,200～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6"> 時のみ</v-col
                  ><v-col cols="6">19,800～</v-col></v-row
                >
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center" class="price-items boder1">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
              <v-card flat>
                <v-card-title> 鳩時計 </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-row
                  ><v-col cols="6">オルゴール付（おもり3個） </v-col
                  ><v-col cols="6">38,500～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6"> 鳩のみ（おもり2個）</v-col
                  ><v-col cols="6">27,500～ </v-col></v-row
                >
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center" class="price-items boder1">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
              <v-card flat>
                <v-card-title> キャリッジクロック </v-card-title>
                <v-card-text class="item-texts">
                  （本ブラス磨きは＋20,000～）
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-row
                  ><v-col cols="6">押し打ち付（リピーター） </v-col
                  ><v-col cols="6"> 66,000～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6"> 時打ち</v-col
                  ><v-col cols="6">38,500～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6">時のみ</v-col
                  ><v-col cols="6">27,500～</v-col></v-row
                >
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center" class="price-items boder1">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
              <v-card flat>
                <v-card-title class="item-title"> 目覚まし時計 </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-row><v-col cols="6"> </v-col><v-col cols="6"></v-col></v-row>
                <v-row
                  ><v-col cols="6"> 目覚まし時計</v-col
                  ><v-col cols="6"> 19,800～</v-col></v-row
                >
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center" class="price-items boder1">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
              <v-card flat>
                <v-card-title class="item-title">
                  船舶時計（八点鐘）
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-row><v-col cols="6"> </v-col><v-col cols="6"></v-col></v-row>
                <v-row
                  ><v-col cols="6"> 船舶時計（八点鐘）</v-col
                  ><v-col cols="6">33,000～</v-col></v-row
                >
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center" class="price-items boder1">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
              <v-card flat>
                <v-card-title class="item-title">
                  アンティーク（古美術）
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-row><v-col cols="6"> </v-col><v-col cols="6"></v-col></v-row>
                <v-row
                  ><v-col cols="6">アンティーク（古美術） </v-col
                  ><v-col cols="6">88,000～</v-col></v-row
                >
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center" class="price-items boder3">
            <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
              <v-card flat>
                <v-card-title class="item-title"> クォーツ時計 </v-card-title>
                <v-card-text class="item-texts">
                  （国産機械へ交換となります）
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-card flat class="price">
                <v-row
                  ><v-col cols="6">機械交換 </v-col
                  ><v-col cols="6"> 7,700～</v-col></v-row
                >
                <v-row
                  ><v-col cols="6"> 振り子付き</v-col
                  ><v-col cols="6">11,000～</v-col></v-row
                >
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center" class="price-text">
      <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
        <v-card flat>
          ・分解掃除の保証期間は１年となります。（状態によっては保証がつけられない場合がございます）<br />
          ・修理ができない場合、ご連絡の上、返却させていただく場合がございます。<br />
          ・上記料金は、分解掃除（分解、洗浄、注油、組み立て、調整）の基本料金です。<br />
          ・部品代、加工代金は別料金が加算されます。<br />
          ・また、上記分類にはない、別の機能が付いている場合も別料金を加算させていただきます。<br />
          ・お支払いは、現金、銀行振込、又はクレジットカードでの決済となります。<br />
          ※銀行振り込みの手数料はお客様負担となります。<br />
          ・出張によるお預かりやお納めには、別料金にて対応させていただきます。<br />
          ・配送修理の場合、11,000円以下の場合、別途往復送料（1,650円）がかかります。
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <img src="@/assets/FA_eMoney___Credit_card_logo-01.png" width="500px" />
    </v-row>
    <div ref="BRANDS"></div>
    <div>
      <v-row justify="center" class="pb-5"><brand-s></brand-s></v-row>
    </div>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
import pageFooter from "@/components/organisms/pageFooter";
import brandS from "@/components/pages/brands";

export default {
  data() {
    return {
      isCancelDialog: false,
      prices: [],
      search_text: "",
      in: 0,
      watch: [],
      intials: ["ア", "カ", "サ", "タ", "ナ", "ハ", "マ", "ヤ", "ラ", "ワ"],
      intial: "",
    };
  },
  mixins: [doAxios],
  components: {
    pageFooter,
    brandS,
  },
  methods: {
    async getPrice() {
      const php = "get_price/getPrice";
      this.prices_temp = await this.doAxios({ php });
      this.prices = this.prices_temp;
      this.set_filter();
    },
    addComma: function (val) {
      if (val == "-") {
        return "-";
      } else if (val == "0") {
        return new Intl.NumberFormat().format(val) + "～";
      } else {
        val = val * 1.1;
        return new Intl.NumberFormat().format(val);
      }
    },

    getIntial(payload) {
      this.intial = payload;
      this.set_filter();
    },
    set_filter() {
      if (this.intial == "" || this.intial == null) {
        this.prices = this.prices_temp;
      } else {
        this.prices = this.prices_temp.filter(
          (value) => value.intial_jp == this.intial
        );
      }
    },
    setSearch() {
      if (this.search_text == "" || this.search_text == null) {
        this.set_filter();
      } else {
        this.prices = this.prices_temp.filter((value) => {
          if (value.brand_name_jp.indexOf(this.search_text) !== -1) {
            return value;
          }
        });
      }
    },
    to_hash(path, Hash) {
      console.log(path);
      if (path == this.$route.name) {
        this.$router.push({ hash: "" });
        this.$router.push({ hash: Hash });
      } else {
        this.$router.push({ name: path, hash: Hash });
      }
    },
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      console.log(el);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.getPrice();

      if (this.$route.hash.match(/^#/)) {
        const el = this.$refs[this.$route.hash.slice(1)];
        el.scrollIntoView({ behavior: "smooth" });
      } else {
        const el = this.$refs["PRICETOP"];
        el.scrollIntoView({ behavior: "smooth" });
      }
    });
  },
  watch: {
    search_text() {
      this.setSearch();
    },
  },
};
</script>
<style scoped>
.price-items {
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.item-title {
  margin-top: -15px;
}
.item-texts {
  text-align: left;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 2px;
  margin-top: -10px;
}
.price-container {
  padding-top: 40px;
}
.price {
  text-align: left;
  font-size: 13px;
  letter-spacing: 2px;
}
.price-text {
  text-align: left;
  font-size: 5px;
  letter-spacing: 2px;
  padding-top: 30px;
  padding-bottom: 20px;
}
.price-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 2px;
}
.price-title-sub {
  padding-top: 40px;
  font-size: 15px;
  letter-spacing: 2px;
}
.font-12 {
  font-size: 12px;
}
.boder3 {
  border-bottom: 3px solid orange;
}
.boder1 {
  border-bottom: 1px solid orange;
}
.text_red {
  color: red;
}
.watch_price {
  overflow-y: auto;
  overflow-x: auto;
}
.price th {
  background-color: cadetblue;
  color: white;
  font-family: sans-serif;
  position: sticky;
  top: 0;
}

.price td {
  border-bottom: solid 1px #dddddd;
  font-family: sans-serif;
}

.th_brand {
  width: 200px;
  text-align: center;
  font-size: 13px;
}
.th_price {
  width: 100px;
  text-align: center;
  font-size: 13px;
}
.td_brand {
  text-align: center;
  font-size: 13px;
}
.td_price {
  text-align: center;
  font-size: 13px;
  padding: 2px;
}
table tr:hover {
  background-color: #ffcc99; /* マウスオーバー時の行の背景色 */
}
table td:hover {
  background-color: #ccffff; /* マウスオーバー時のセルの背景色 */
}
.font-10 {
  font-size: 10pt;
}
</style>
