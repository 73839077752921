<template>
  <div class="mt-10">
    <div ref="TOP"></div>
    <div class="top-box">
      <div class="slide">
        <img width="100%" src="/imgs/brands/clock.jpg" alt="" />
      </div>
      <p class="top-left">CLOCK<br /></p>
    </div>
    <v-row justify="center">
      <v-card width="90%" class="mt-5" flat>
        <div class="second-box-title mt-2 ml-2">
          <h2>クロック（置時計、掛時計）修理</h2>
        </div>
        <v-card class="store-box">
          街の時計屋さんが少なくなるにともない、クロックの修理を出来る職人も減ってしまいました。<br />
          タイムリペアでは、クロック専門の職人が在籍しており、ホールクロックから、ボンボン時計、アンティーク時計、
          キャリッジクロックまで幅広く対応させていただいております。
          まずは一度、ご相談ください。<br />
        </v-card>

        <div class="event-title"><h2 class="ml-2">★PRICE</h2></div>
        <div ref="CLOCKPRICE">
          <v-row justify="center" class="price-container">
            <v-col cols="8">
              <v-row justify="center">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat>
                    <v-card-title class="price-title"> Clock </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-card-title> 納期：1カ月～</v-card-title>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="boder3 mt-12"></v-row>

              <v-row justify="center" class="price-items boder1">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                  <v-card flat>
                    <v-card-title> ホールクロック </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-row
                      ><v-col cols="6">3チャイム/夜間停止/複雑 </v-col
                      ><v-col cols="6">77,000～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6"> チャイム（15分打ち）</v-col
                      ><v-col cols="6"> 60,500～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6"> 時打ちのみ</v-col
                      ><v-col cols="6">44,000～</v-col></v-row
                    >
                    別途出張料金がかかります。
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="center" class="price-items boder1">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                  <v-card flat>
                    <v-card-title> 置時計/掛時計（柱時計） </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-row
                      ><v-col cols="6">曲切替え/夜間停止/複雑 </v-col
                      ><v-col cols="6">66,000～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6"> チャイム（15分打ち）</v-col
                      ><v-col cols="6"> 55,000～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6"> 時打ちのみ（ボンボン）</v-col
                      ><v-col cols="6">24,200～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6"> 時のみ</v-col
                      ><v-col cols="6">19,800～</v-col></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="center" class="price-items boder1">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                  <v-card flat>
                    <v-card-title> 鳩時計 </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-row
                      ><v-col cols="6">オルゴール付（おもり3個） </v-col
                      ><v-col cols="6">38,500～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6"> 鳩のみ（おもり2個）</v-col
                      ><v-col cols="6">27,500～ </v-col></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="center" class="price-items boder1">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                  <v-card flat>
                    <v-card-title> キャリッジクロック </v-card-title>
                    <v-card-text class="item-texts">
                      （本ブラス磨きは＋20,000～）
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-row
                      ><v-col cols="6">押し打ち付（リピーター） </v-col
                      ><v-col cols="6"> 66,000～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6"> 時打ち</v-col
                      ><v-col cols="6">38,500～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6">時のみ</v-col
                      ><v-col cols="6">27,500～</v-col></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="center" class="price-items boder1">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                  <v-card flat>
                    <v-card-title class="item-title">
                      目覚まし時計
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-row
                      ><v-col cols="6"> </v-col><v-col cols="6"></v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="6"> 目覚まし時計</v-col
                      ><v-col cols="6"> 19,800～</v-col></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="center" class="price-items boder1">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                  <v-card flat>
                    <v-card-title class="item-title">
                      船舶時計（八点鐘）
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-row
                      ><v-col cols="6"> </v-col><v-col cols="6"></v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="6"> 船舶時計（八点鐘）</v-col
                      ><v-col cols="6">33,000～</v-col></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="center" class="price-items boder1">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                  <v-card flat>
                    <v-card-title class="item-title">
                      アンティーク（古美術）
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-row
                      ><v-col cols="6"> </v-col><v-col cols="6"></v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="6">アンティーク（古美術） </v-col
                      ><v-col cols="6">88,000～</v-col></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="center" class="price-items boder3">
                <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                  <v-card flat>
                    <v-card-title class="item-title">
                      クォーツ時計
                    </v-card-title>
                    <v-card-text class="item-texts">
                      （国産機械へ交換となります）
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-card flat class="price">
                    <v-row
                      ><v-col cols="6">機械交換 </v-col
                      ><v-col cols="6"> 7,700～</v-col></v-row
                    >
                    <v-row
                      ><v-col cols="6"> 振り子付き</v-col
                      ><v-col cols="6">11,000～</v-col></v-row
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-card flat width="90%" class="mx-auto mt-8">
            ・分解掃除の保証期間は１年となります。（状態によっては保証がつけられない場合がございます）<br />
            ・修理ができない場合、ご連絡の上、返却させていただく場合がございます。<br />
            ・上記料金は、分解掃除（分解、洗浄、注油、組み立て、調整）の基本料金です。<br />
            ・部品代、加工代金は別料金が加算されます。<br />
            ・また、上記分類にはない、別の機能が付いている場合も別料金を加算させていただきます。<br />
            ・お支払いは、現金、銀行振込、又はクレジットカードでの決済となります。<br />
            ※銀行振り込みの手数料はお客様負担となります。<br />
            ・出張によるお預かりやお納めには、別途出張費を頂戴いたします。<br />
            ・出張によるお預かりやお納めは、関東のみとさせて頂きます。場所によりご希望に添えない場合もございます。<br />
            ・配送修理の場合でも、事前に一度ご連絡くださいませ。
          </v-card>
        </div>

        <div>
          <v-row justify="center"
            ><achievement v-bind:propsItems="{ brand_id: 0 }"></achievement
          ></v-row>
        </div>
        <div><store></store></div>
        <div>
          <v-row justify="center" class="pb-5"><brand-s></brand-s></v-row>
        </div>
        <v-card class="mt-5 mb-10" flat id="QA">
          <v-row justify="center">
            <div class="event-title"><h2>よくある質問</h2></div>
          </v-row>
          <qand-a v-bind:propsItems="{ is_insert: 1 }"></qand-a>
        </v-card>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
import achievement from "@/components/pages/achievement";
import qandA from "@/components/pages/QandA";
import store from "@/components/template/insertStore";
import brandS from "@/components/pages/brands";
export default {
  data() {
    return {};
  },
  mixins: [doAxios],
  components: {
    //  numberCount,
    qandA,
    achievement,
    store,
    brandS,
  },
  methods: {
    createPageTitle(to) {
      // タイトルを設定
      if (to.meta.title) {
        var setTitle = to.meta.title + "";
        document.title = setTitle;
      } else {
        document.title = "クロック・置時計・掛時計　時計修理専門店-TimeRepair-";
      }
    },
  },
  mounted() {
    if (this.$route.hash.match(/^#/)) {
      const el = this.$refs[this.$route.hash.slice(1)];
      el.scrollIntoView({ behavior: "smooth" });
    } else {
      const el = this.$refs["TOP"];
      el.scrollIntoView({ behavior: "smooth" });
    }
    this.createPageTitle;
  },
  watch: {},
};
</script>
<style scoped>
/*枠の大きさ*/
.top-box {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  background-color: rgba(19, 53, 128, 0.8);
}
.second-box-title {
  border: 3px solid;
  border-left-color: rgb(9, 85, 2);
  border-top: none;
  border-bottom: none;
  border-right: none;
}
.price-container {
  padding-top: 40px;
}
.price {
  text-align: left;
  font-size: 13px;
  letter-spacing: 2px;
}

.price-title {
  text-align: left;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.font-12 {
  font-size: 12px;
}
.boder3 {
  border-bottom: 3px solid orange;
}
.boder1 {
  border-bottom: 1px solid orange;
}
.price th {
  background-color: cadetblue;
  color: white;
  font-family: sans-serif;
}
.price td {
  border-bottom: solid 1px #dddddd;
  font-family: sans-serif;
}

.price tr:hover {
  background-color: #ffcc99; /* マウスオーバー時の行の背景色 */
}
.price td:hover {
  background-color: #ccffff; /* マウスオーバー時のセルの背景色 */
}
.store-box {
  margin-top: 10px;
  border: 2px solid #a5964b; /* 境界線を実線で指定 */
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(19, 53, 128, 0.2);
  min-height: 200px;
  padding: 1%;
  overflow: hidden;
}

.top-left {
  position: absolute;
  top: 20%;
  left: 10%;
  color: white;
  font-size: 5rem;

  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.14em;
  transform: scaleX(0.825);
  transform-origin: left center;
  text-shadow: 4px 5px 4px rgb(167, 95, 54);
}

.slide {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  background: white;
}

.slide img {
  position: absolute;
  top: 20%;
  left: 50%;

  width: calc(100% + 100px);
  opacity: 0;
  animation: slideshow 8s both 1;
}
.event-title {
  width: 80%;
  color: darkblue;
  background: linear-gradient(to right, pink, #ffe8ff);
  margin: 15px auto 0;
}
@keyframes slideshow {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  10% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -40%);
  }
}
</style>
