<template >
<div>

<v-app-bar clipped-left height="70px" app  hide-on-scroll>

    <div>
      <v-toolbar-items class="img-bc sub-color-bc" >
            <v-img   aspect-ratio="1"  src="https://time-repair.jp/ohmiya/imgs/S__12410889.png" ></v-img>
            </v-toolbar-items>
    </div>
    <div>
      <v-toolbar-title  class="app-title sub-color-bc" >
        <div>
          <router-link to="/"  class="deco"><span class="sub-title">時計修理専門</span><br><span class="titles">TimeRepair<span class="font-16" >-大宮店-</span></span></router-link>
        </div>
      </v-toolbar-title>
    </div> 
       <v-spacer></v-spacer>
        <v-toolbar-items>  
           <v-menu >
             <template v-slot:activator="{on}">                     
            <v-btn class="btn mr-5" v-show="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm" v-on="on" text>メニュー<v-icon>mdi-menu-down</v-icon></v-btn>
            </template>
            <v-list>
                <v-list-item v-for="support in supports" :key="support.name"  @click="to_hash(support.root,support.hash,support.method)">
                    <v-list-item-icon><v-icon>{{ support.icon }}</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title >{{ support.name }}</v-list-item-title></v-list-item-content>
                </v-list-item>
            </v-list>
            </v-menu>
             <div   v-show="$vuetify.breakpoint.xl||$vuetify.breakpoint.lg||$vuetify.breakpoint.md">
         
               </div>    
          </v-toolbar-items>   
              <v-row justify="center"  v-show="$vuetify.breakpoint.xl||$vuetify.breakpoint.lg||$vuetify.breakpoint.md">
                <v-btn text class="menu-btn" to="numberCallView">呼び出し</v-btn>   
                <v-btn text class="menu-btn" to="entryForm">受付登録</v-btn>                            
                <v-btn text class="menu-btn" to="StatusView">ステータスビュー</v-btn>
                <v-btn text class="menu-btn" to="inputCustomer">顧客情報変更</v-btn>
                <v-btn text class="menu-btn" @click="set_logout()">ログアウト</v-btn>                
         </v-row>
    </v-app-bar>
    <v-dialog v-model="this.$store.state.isProgress" persistent>

  <progress-rabbit></progress-rabbit>

    </v-dialog>


</div>

</template>
    
<script>
import progressRabbit from '@/components/organisms/progress_r'
export default {
  name: 'sample2',
      components: {
        progressRabbit
      },

  data(){
    return{

         message: "",
         item_l:true,
         item_r:true,
        drawer: null,
        supports:[
            {name: 'Top',icon: '',root: 'Home',hash:'#HOME'},    
            {name: 'ステータス',icon: '',root: 'statusView',hash:''}, 
            {name: '料金表',icon: '',root: 'price',hash:''},             
            {name: '受付',icon: '',root: 'entryForm',hash:''}, 
            {name:'ログアウト',icon:'mdi-exit-run',route: '',method:'set_logout'},
        ],

  }},
  methods:{
     to_hash(path,Hash,thisMethod){
       console.log(path,Hash,)
     if(thisMethod==''||thisMethod==null){
     if(path==this.$route.name){
       this.$router.push({hash:''})
       this.$router.push({hash:Hash})
     }else{
       this.$router.push({name:path,hash:Hash})
     }

     }else{
        switch (thisMethod){
            case 'set_menu':
            //  this.set_menu()
            break
            case 'set_logout':
              this.set_logout()
              break
          }
     }


    },
   
    set_logout(){
        this.$store.dispatch('authToken', "")
        this.$store.dispatch('authId', "")   
        this.$store.dispatch('authLoginToken', '')
        localStorage.token = ''
        localStorage.uid = ''  
        sessionStorage.from = ''
        this.$router.push('/Login')
    }
  }
}

</script>
<style scoped>

.deco{
      text-decoration: none;

}
.sub-title{
    color: #ffffff;
    font-family: 'georgiai';
    text-align: left;
    font-size:11px;
    text-align: center;
   margin-left: 15px;

}
.titles{
    color: #ffffff;
    font-family: 'georgiai';
    font-size:18px;
    margin-left: 15px;
    margin-top:-10px
}

.img-bc{

  color:#ffffff;
  text-align: center;
  height: 70px;
  width: 50px;
  margin-left:-15px;
  margin-bottom: 0px;

}
.app-title{
  color:#ffffff;

  width: 200px;
  height: 70px;
  margin-left:-10px;
}
.tel{
  color: black;
  font-size:28px;
      font-family: 'georgiai';
}
.font-16{
  font-size:16px;
}



</style>