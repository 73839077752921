<template>
  <div class="mt-10">
    <div ref="TOP"></div>
    <div class="top-box">
      <div class="slide">
        <img width="100%" src="/imgs/brands/IWC_AC.jpeg" alt="" />
      </div>
      <p class="top-left">IWC<br /></p>
    </div>
    <v-row justify="center">
      <v-card width="90%" class="mt-5" flat>
        <div class="second-box-title mt-2 ml-2">
          <h2>IWC/インターナショナル修理</h2>
        </div>
        <v-card class="store-box">
          IWC（International Watch
          Company）は、スイスのシャフハウゼンに本社を置く高級時計ブランドです。<br />
          1868年に創業されたIWCは、高品質で精密なムーブメントや高級素材を使用し、多くの時計愛好家に高い評価を受けています。<br />
          正規の販売価格の上昇とともに、メーカーのアフターサービスの価格も上昇しております。<br />
          タイムリペアでは、中間コストを排除して弊社工房にて修理・メンテナンスをすることにより
          価格を抑えながら高品質な作業を提供しております。<br />
          元日本ロレックスの公認技術者をはじめ、多くの1級時計修理技能士が修理を担当しておりますので
          安心して修理をご依頼ください。<br />
        </v-card>

        <div class="event-title"><h2 class="ml-2">★PRICE</h2></div>

        <v-row justify="center" class="price-container">
          <v-col cols="11">
            <v-row justify="center" class="boder3">
              <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-card flat>
                  <v-card-title class="price-title">
                    オーバーホール
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-card flat class="price">
                  <v-card-title> 納期：1カ月～</v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center" class="price-items boder1">
              <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                <v-card flat>
                  <v-card-title> クォーツ </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-card flat>
                  <v-card-title>25,300～</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="10" class="price">主なモデル：ポートフィノ</v-col>
            </v-row>
            <v-row justify="center" class="price-items boder1">
              <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                <v-card flat>
                  <v-card-title> クォーツ（クロノグラフ）</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-card flat>
                  <v-card-title>36,300～</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" class="price text-center"></v-col>
            </v-row>
            <v-row justify="center" class="price-items boder1">
              <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                <v-card flat>
                  <v-card-title> 機械式（自動巻き） </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-card flat>
                  <v-card-title>34,100～</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="10" class="price">主なモデル：ポートフィノ</v-col>
            </v-row>
            <v-row justify="center" class="price-items boder1">
              <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                <v-card flat>
                  <v-card-title> 機械式（手巻き）</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-card flat>
                  <v-card-title>30,800～</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" class="price text-center"></v-col>
            </v-row>
            <v-row justify="center" class="price-items boder1">
              <v-col cols="auto" xs="12" sm="6" md="6" lg="4" xl="4">
                <v-card flat>
                  <v-card-title> 機械式（クロノグラフ）</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="auto" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-card flat>
                  <v-card-title>41,800～</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="10" class="price"
                >主なモデル：ポルトギーゼ、アクアタイマー、パイロットクロノ</v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-card width="100%" class="text-right" flat> </v-card>
        </v-row>
      </v-card>
    </v-row>
    <div>
      <v-row justify="center"
        ><achievement v-bind:propsItems="{ brand_id: 12 }"></achievement
      ></v-row>
    </div>
    <div><store></store></div>
    <div>
      <v-row justify="center" class="pb-5"><brand-s></brand-s></v-row>
    </div>
    <v-card class="mt-5 mb-10" flat id="QA">
      <v-row justify="center">
        <div class="event-title"><h2>よくある質問</h2></div>
      </v-row>
      <qand-a v-bind:propsItems="{ is_insert: 1 }"></qand-a>
    </v-card>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
import achievement from "@/components/pages/achievement";
import qandA from "@/components/pages/QandA";
import store from "@/components/template/insertStore";
import brandS from "@/components/pages/brands";
export default {
  data() {
    return {};
  },
  mixins: [doAxios],
  components: {
    //  numberCount,
    qandA,
    achievement,
    store,
    brandS,
  },
  methods: {
    createPageTitle(to) {
      // タイトルを設定
      if (to.meta.title) {
        var setTitle = to.meta.title + "";
        document.title = setTitle;
      } else {
        document.title = "IWC　時計修理専門店-TimeRepair-";
      }
    },
  },
  mounted() {
    if (this.$route.hash.match(/^#/)) {
      const el = this.$refs[this.$route.hash.slice(1)];
      el.scrollIntoView({ behavior: "smooth" });
    } else {
      const el = this.$refs["TOP"];
      el.scrollIntoView({ behavior: "smooth" });
    }
    this.createPageTitle;
  },
  watch: {},
};
</script>
<style scoped>
/*枠の大きさ*/
.top-box {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  background-color: rgba(19, 53, 128, 0.8);
}
.second-box-title {
  border: 3px solid;
  border-left-color: rgb(9, 85, 2);
  border-top: none;
  border-bottom: none;
  border-right: none;
}
.price-container {
  padding-top: 40px;
}
.price {
  text-align: left;
  font-size: 13px;
  letter-spacing: 2px;
}

.price-title {
  text-align: left;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.font-12 {
  font-size: 12px;
}
.boder3 {
  border-bottom: 3px solid orange;
}
.boder1 {
  border-bottom: 1px solid orange;
}
.price th {
  background-color: cadetblue;
  color: white;
  font-family: sans-serif;
}
.price td {
  border-bottom: solid 1px #dddddd;
  font-family: sans-serif;
}

.price tr:hover {
  background-color: #ffcc99; /* マウスオーバー時の行の背景色 */
}
.price td:hover {
  background-color: #ccffff; /* マウスオーバー時のセルの背景色 */
}
.store-box {
  margin-top: 10px;
  border: 2px solid #a5964b; /* 境界線を実線で指定 */
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(19, 53, 128, 0.2);
  min-height: 200px;
  padding: 1%;
  overflow: hidden;
}

.top-left {
  position: absolute;
  top: 20%;
  left: 10%;
  color: white;
  font-size: 5rem;

  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.14em;
  transform: scaleX(0.825);
  transform-origin: left center;
  text-shadow: 4px 5px 4px rgb(167, 95, 54);
}

.slide {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  background: white;
}

.slide img {
  position: absolute;
  top: 20%;
  left: 50%;

  width: calc(100% + 100px);
  opacity: 0;
  animation: slideshow 8s both 1;
}
.event-title {
  width: 80%;
  color: darkblue;
  background: linear-gradient(to right, pink, #ffe8ff);
  margin: 15px auto 0;
}
@keyframes slideshow {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  10% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -40%);
  }
}
</style>
