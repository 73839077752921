<template>
  <div class="mt-10">
    <v-row class="boder3"></v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-card class="mt-5" flat>
          <v-row justify="center">
            <v-card flat class="d-box mt-5">
              <v-row justify="center"
                ><v-card-title class="justify-center bk mt-5 mb-5"
                  >お知らせ</v-card-title
                ></v-row
              >
              <table class="" id="NEWSTOP" v-if="notice">
                <tbody>
                  <tr>
                    <th>日付</th>
                    <th>内容</th>
                  </tr>
                  <tr v-for="(item, i) in notice" :key="i">
                    <td>{{ item.date }}</td>
                    <td>{{ item.notice }}</td>
                  </tr>
                </tbody>
              </table>

              <v-row justify="center" no-gutters>
                <div class="border-news"></div>
              </v-row>
            </v-card>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return { notice: [] };
  },
  mixins: [doAxios],

  methods: {
    async getNotice() {
      const php = "number_call/getNotice";
      this.notice = await this.doAxios({ php });
    },
  },
  mounted() {
    this.getNotice();
  },
  watch: {},
};
</script>
<style scoped>
.bk {
  background: #4f7594;
  color: #ffffff;
  width: 80%;
  text-align: center;
}
table {
  border: 3px solid #4f7594;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  width: 350px;
}
table thead th,
table tbody th,
table tbody td {
  border-bottom: 1px solid #aaa;
}
table th {
  background-color: #4f7594;
  color: #ffffff;
}
</style>
