<template>
  <div>
    <v-row justify="center" class="mt-8">
      <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
        <v-row justify="center">
          <div class="store-box">
            <v-row justify="center">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="text-center"
              >
                <router-link to="/ohmiyaten" class="deco"
                  ><img
                    class="pl-3 pt-3"
                    width="100%"
                    src="/imgs/img3.jpg"
                  />《詳しくはクリック》</router-link
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-row justify="center">
                  <v-card
                    height="350px"
                    width="100%"
                    class="ml-5 pl-5 pt-3"
                    flat
                  >
                    <h2>
                      <router-link to="/ohmiyaten" class="deco mb-2"
                        >大宮店</router-link
                      >
                    </h2>
                    <br />

                    <v-row>
                      <div class="address-font">
                        〒330-0845<br />
                        埼玉県さいたま市大宮区仲町2-24-2<br />金杉仲町ビル
                        １階<br />
                        タイガー薬局様となり
                      </div>
                    </v-row>
                    <v-row>
                      <div class="time-font tel mt-3">
                        <a href="tel:048-662-9300">TEL:048-662-9300</a>
                      </div>
                    </v-row>

                    <v-row>
                      <div class="time-font">
                        営業時間:10:30-19:30<br />
                        定休日:金曜日
                      </div>
                    </v-row>
                    <v-row>
                      <span v-if="notice" class="address-font mt-3 mr-4 mb-2">{{
                        dispNotice()
                      }}</span>
                    </v-row>
                    <v-row>
                      <v-btn
                        color="green"
                        dark
                        small
                        href="https://lin.ee/1LW6qTQ"
                        target="_blank"
                      >
                        公式LINE
                      </v-btn>
                      <v-btn
                        color="pink"
                        dark
                        small
                        href="mailto:ohmiya@time-repair.com?subject=%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body= %0d%0a 
%E3%80%90%E3%81%8A%E5%90%8D%E5%89%8D%E3%80%91%EF%BC%9A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20 様 %0d%0a  %0d%0a
%E3%80%90%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%80%91%EF%BC%9A%20%0d%0a
%E3%83%A2%E3%83%87%E3%83%AB%E7%95%AA%E5%8F%B7%E7%AD%89%E3%82%8F%E3%81%8B%E3%82%8C%E3%81%B0%E8%A8%98%E8%BC%89%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84(%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20)  %0d%0a %0d%0a
%E3%80%90%E7%97%87%E7%8A%B6%E3%80%91%EF%BC%9A%20 %0d%0a  %0d%0a 
%E2%80%BB%E5%8F%AF%E8%83%BD%E3%81%A7%E3%81%82%E3%82%8C%E3%81%B0%E3%81%8A%E6%99%82%E8%A8%88%E3%81%AE%E5%86%99%E7%9C%9F%E3%82%92%E6%B7%BB%E4%BB%98%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82"
                      >
                        メール
                      </v-btn>
                    </v-row>
                  </v-card>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
        <v-row justify="center">
          <div class="store-box">
            <v-row justify="center">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <img class="pl-3 pt-3" width="100%" src="/imgs/img6.jpg" />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-row justify="center">
                  <v-card
                    height="350px"
                    width="100%"
                    class="ml-5 pl-5 pt-3"
                    flat
                  >
                    <h2>
                      <router-link to="/ohmiya" class="deco mb-2"
                        >神田店</router-link
                      >
                    </h2>
                    <br />

                    <v-row>
                      <div class="address-font">
                        神田店は技術者が常駐していないため、<br />
                        受付のみとなります。<br />
                        担当者不在の時間がございます。<br />
                        一度お電話にて確認をお願いします。<br />
                      </div>
                    </v-row>
                    <v-row>
                      <div class="time-font tel mt-3">
                        <a href="tel:070-7102-8107">TEL:080-7102-8107</a>
                      </div>
                    </v-row>

                    <v-row>
                      <div class="time-font">
                        営業時間:10:00-16:30<br />
                        定休日:土・日・祝
                      </div>
                    </v-row>
                  </v-card>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-8">
      <v-col cols="10">
        <v-row justify="center">
          <div class="store-box-under color_pink">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <router-link :to="{ name: 'timeRepairOnline' }" class="deco">
                  <span class="online-title">TimeRepair オンライン</span><br />
                </router-link>
                <h3>
                  配送無料
                  <router-link :to="{ name: 'timeRepairOnline' }">
                    《詳細はこちら》</router-link
                  >
                </h3>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <span class="online-sub-title"
                  >来店できない方は配送にて承ります。まずは一度ご相談ください。</span
                >
                <v-row class="mb-2">
                  <v-spacer></v-spacer>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn
                      color="green"
                      dark
                      href="https://lin.ee/1LW6qTQ"
                      target="_blank"
                    >
                      気軽にLINEで相談
                    </v-btn>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn color="primary" dark href="tel:048-662-9300">
                      電話で相談
                    </v-btn>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn
                      color="pink"
                      dark
                      href="mailto:online@time-repair.com?subject=%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body= %0d%0a 
%E3%80%90%E3%81%8A%E5%90%8D%E5%89%8D%E3%80%91%EF%BC%9A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20 様 %0d%0a  %0d%0a
%E3%80%90%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%80%91%EF%BC%9A%20%0d%0a
%E3%83%A2%E3%83%87%E3%83%AB%E7%95%AA%E5%8F%B7%E7%AD%89%E3%82%8F%E3%81%8B%E3%82%8C%E3%81%B0%E8%A8%98%E8%BC%89%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84(%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20)  %0d%0a %0d%0a
%E3%80%90%E7%97%87%E7%8A%B6%E3%80%91%EF%BC%9A%20 %0d%0a  %0d%0a 
%E2%80%BB%E5%8F%AF%E8%83%BD%E3%81%A7%E3%81%82%E3%82%8C%E3%81%B0%E3%81%8A%E6%99%82%E8%A8%88%E3%81%AE%E5%86%99%E7%9C%9F%E3%82%92%E6%B7%BB%E4%BB%98%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82"
                    >
                      メールで相談
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-col>
      <v-col cols="10">
        <v-row justify="center">
          <div class="store-box-under color_green">
            <router-link :to="{ name: 'repairCenter' }" class="deco"
              ><v-row justify="center">
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                  <img
                    class="pl-3"
                    height="80px"
                    src="/imgs/center/koubou.jpg"
                  />
                  <img class="pl-3" height="80px" src="/imgs/IMG_0025.jpg" />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <div class="address-font">
                    <h2>工房について</h2>
                    時計修理技能士1級を所持した技術者が、
                    お客様の時計を入念に分解・洗浄・注油・組立・調整を行っております。
                    《詳細はこちら→》
                  </div>
                </v-col>
              </v-row>
            </router-link>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return {
      notice: [],
    };
  },
  mixins: [doAxios],
  components: {
    //  numberCount,
  },
  methods: {
    async getNotice() {
      const php = "number_call/getNotice";
      this.notice = await this.doAxios({ php });
      console.log(this.notice);
    },
    dispNotice() {
      if (this.notice == null || this.notice == "") {
        return;
      } else {
        const res = this.notice.filter((value) => value.notice_type == 1);
        if (res == null || res == "") {
          return;
        } else {
          return res[0].notice;
        }
      }
    },
  },
  mounted() {
    this.getNotice();
  },
  //watch: {},
};
</script>

<style scoped>
/*枠の大きさ*/

.store-box {
  margin-top: 10px;
  border: 2px solid #a5964b; /* 境界線を実線で指定 */
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(19, 53, 128, 0.2);
  width: 90%;
  min-height: 300px;
  padding: 1%;
  overflow: hidden;
}
.store-box-under {
  margin-top: 10px;

  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(19, 53, 128, 0.2);
  width: 95%;
  min-height: 100px;
  padding: 1%;
  overflow: hidden;
}
.store-box-under.color_pink {
  border: 2px solid #ff4863;
}

.store-box-under.color_green {
  border: 2px solid #a5964b;
}
.online-title {
  font-weight: 700;
  font-size: 1.6rem;
}
.online-sub-title {
  color: #ff4863;
  font-size: 1.3rem;
}
.deco {
  text-decoration: none;
  color: black;
}
.address-font {
  font-size: 0.8rem;
}
</style>
