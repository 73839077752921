<template>
  <div>
    <v-card width="80%" class="mx-auto" flat>
      <div class="mt-8">
        <h2 class="text-center" v-if="isInsert == 0">よくある質問</h2>
      </div>
      <v-row justify="center">
        <v-col cols="auto" lg="10" xl="10" md="10" sm="12" xs="12">
          <v-row justify="center">
            <v-col cols="auto" lg="10" xl="10" md="10" sm="12" xs="12">
              <v-expansion-panels focusable>
                <v-expansion-panel v-for="(item, i) in results" :key="i">
                  <v-expansion-panel-header>{{
                    item.question
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ item.answer }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return {
      results: [],
      isInsert: 0,
    };
  },
  mixins: [doAxios],
  props: ["propsItems"],
  methods: {
    async getQA() {
      const php = "number_call/getQandA";
      this.results = await this.doAxios({ php });
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.getQA();
      if (this.propsItems != null) {
        this.isInsert = this.propsItems["is_insert"];
      }
    });
  },
};
</script>
<style scoped>
.padding-10 {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.padding-20 {
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.box {
  text-align: left;
  font-size: 18px;
  height: 30px;
  padding-left: 50px;
  background-color: #dbce7a;
}
</style>
