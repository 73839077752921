<template>
  <div class="mt-10">
    <!-- プライバシーポリシー Section Start ---------------------------------------------------------------->
    <v-card width="80%" class="mx-auto mt-10 box" flat>
      <h2 class="text-center mt-5 mb-5">プライバシーポリシー</h2>
      <p>
        当社＝株式会社長野商店は、取扱う個人情報の重要性を認識し、以下の個人情報保護方針を定め、<br />
        これを遵守し、適切な個人情報保護の取扱い、管理及び保護に努めます。
      </p>

      <p class="about"><b>■個人情報の管理について</b></p>
      <p>
        ・弊社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
      </p>
      <hr />
      <p class="about"><b>■個人情報の利用目的</b></p>
      <p>
        ・弊社では、お客様からのお問い合わせ時に、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
      </p>
      <hr />
      <p class="about"><b>■第三者提供について</b></p>
      <p>
        ・弊社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。<br />
        （1）お客さまの同意がある場合<br />
        （2）お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合<br />
        （3）法令に基づき開示することが必要である場合
      </p>
      <hr />
      <p class="about"><b>■ご本人の照会</b></p>
      <p>
        ・お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
      </p>
      <hr />
      <p class="about"><b>■お問い合わせ</b></p>
      <p>
        ・弊社の個人情報の取扱に関するお問い合せは下記までご連絡ください。<br />
        株式会社 長野商店 〒101-0021 東京都千代田区外神田6丁目15番11号<br />
        TEL 03-6803-0531 FAX 03-6803-0541
      </p>
    </v-card>
    <!-- プライバシーポリシー Section End ---------------------------------------------------------------->
    <div class="mt-16 mb-5 text-center">
      <contract-footer class="mt-15"></contract-footer>
    </div>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";
import contractFooter from "@/components/organisms/contractFooter";

export default {
  data() {
    return { notice: [] };
  },
  mixins: [doAxios],

  components: { contractFooter },
  methods: {
    async getNotice() {
      const php = "number_call/getNotice";
      this.notice = await this.doAxios({ php });
    },
  },
  mounted() {
    this.getNotice();
  },
  watch: {},
};
</script>
<style scoped>
.about {
  padding: 50px 0 0 0px;
}

.bk {
  background: #4f7594;
  color: #ffffff;
  width: 80%;
  text-align: center;
}
table {
  border: 3px solid #4f7594;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
}
table thead th,
table tbody th,
table tbody td {
  border-bottom: 1px solid #aaa;
  height: 20px;
  padding-left: 20px;
}
table th {
  background-color: #4f7594;
  color: #ffffff;
  width: 30%;
}
table tr {
  height: 50px;
}
</style>
