<template>
  <div class="mt-10">
    <v-card class="mt-n5" height="250px" flat>
      <div class="event-title"><h2>EVENT</h2></div>
      <div class="event-list">
        <ul v-for="(item, i) in events" :key="i">
          <li>
            {{ item.text }}
          </li>
        </ul>
      </div>
    </v-card>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return { events: [] };
  },
  mixins: [doAxios],

  methods: {
    async getEvents() {
      const php = "number_call/getEvents";
      this.events = await this.doAxios({ php });
    },
  },
  mounted() {
    this.getEvents();
  },
  watch: {},
};
</script>
<style scoped>
.event-title {
  width: 100%;
  color: rgb#CCC;
  font-size: 1.2rem;
  font-family: honoka, serif;
  margin-top: 10%;
  text-align: center;
}
.event-list {
  margin: 15px auto 0;
  color: #242424;
}
.event-list li {
  border-bottom: 1px solid #ff4863;
  list-style-position: inside;
  padding: 10px;
}
</style>
