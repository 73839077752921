<template>
  <div>
    <!---->
    <v-card class="scroll mt-8 mx-auto" flat width="50%">
      <div>
        <div>
          <v-btn x-small @click="imageShows()">
            {{ isImageShows ? "他の画像を閉じる" : "他の画像を見る" }}
          </v-btn>
        </div>
        <div>
          <v-row
            no-gutters
            v-show="!isShow"
            v-for="(images, i) in imagedatas"
            :key="i"
          >
            <v-col
              v-for="(image, i) in images"
              :key="i"
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <v-card>
                <v-img
                  :src="image_dir + image.folder + '/' + image.file_name"
                  aspect-ratio="1"
                  :contain="true"
                  @click="
                    zoomImage(image_dir + image.folder + '/' + image.file_name)
                  "
                ></v-img>
                <v-btn
                  x-small
                  v-if="$store.state.LoginToken == 'stafflogin'"
                  @click="
                    isDeleted(
                      image_dir + image.folder + '/' + image.file_name,
                      image.id
                    )
                  "
                  >削除</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-btn @click="imageDialog = !imageDialog" color="primary"
          >写真を登録する</v-btn
        >

        <v-card-text>
          <v-form ref="form" v-model="isForm">
            <brand-select
              @get-brand="getChildBrand"
              @get-brand-sub="getChildBrandSub"
            ></brand-select>
            ブランドID: {{ brandId }}
            <v-card-actions
              >タイトル：<v-text-field
                dense
                v-model="title"
                :rules="rules"
              ></v-text-field
              ><br
            /></v-card-actions>
            <v-card-actions>
              タイムリペア金額:<v-text-field
                dense
                v-model="price"
                append-icon="円"
                :rules="priceRules"
              ></v-text-field
              >（税込）<br
            /></v-card-actions>
            <v-card-actions
              >参考デパート金額:<v-text-field
                dense
                v-model="dPrice"
                append-icon="円"
                :rules="priceRules"
              ></v-text-field
              >（税込）<br
            /></v-card-actions>
            <v-card-actions
              >修理内容など:<v-textarea
                height="200px"
                dense
                v-model="comment"
                :rules="rules"
              ></v-textarea
              ><br
            /></v-card-actions>

            <span>{{ imgUrl }}</span>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="setAcievement()"
            color="priary"
            :disabled="!isForm || !isImg || !isBrand"
            >登録</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>

    <v-dialog v-model="imageDialog" max-width="600" persistent>
      <v-card>
        <image-up-load
          pt-10
          @get-images="getChildImages"
          @get-is-images="getChildIsImages"
          v-bind:clear="clearImage"
        ></image-up-load>
        <v-btn
          @click="setInputImage()"
          block
          v-if="watch_images.length > 0"
          color="primary"
          >写真を登録する</v-btn
        >
        <v-btn @click="imageDialog = !imageDialog" block>閉じる</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import doAxios from "@/components/modules/AxiosPost";
import ImageUpLoad from "@/components/organisms/ImageUpLoad.vue";
import BrandSelect from "@/components/organisms/BrandSelect.vue";

export default {
  data() {
    return {
      imageDialog: false, //
      isImageShows: false,
      isForm: false,
      isShow: false,
      clearImage: false,
      isImg: false,
      isBrand: false,
      topImage: [{ top_image: "" }],
      imagedatas: [],
      watch_images: "",
      image: "https://enhance-watchservice.com/time_repair/api/upload/images/",
      image_dir:
        "https://enhance-watchservice.com/time_repair/api/upload/images/",
      img_url: "",
      title: "",
      price: "",
      dPrice: "",
      comment: "",
      brandId: 0,
      imgUrl: "",
      priceRules: [
        (v) => (v && v.length <= 7) || "7桁までの整数で必須項目です。",
        (v) => /^[+,-]?([1-9]\d*|0){0,8}$/.test(v) || "7桁までの整数です。",
      ],
      rules: [(v) => !!v || "必須項目です。"],
    };
  },
  props: ["propsItem"],
  mixins: [doAxios],
  components: {
    ImageUpLoad,
    BrandSelect,
  },
  mounted: function () {},
  methods: {
    async setAcievement() {
      this.$store.dispatch("authIsProgress", true);
      const price = this.priceFormat(this.price) + "円";
      const dPrice = this.priceFormat(this.dPrice) + "円";
      const setData = {
        img_url: this.imgUrl,
        title: this.title,
        price: price,
        d_price: dPrice,
        comment: this.comment,
        brand_id: this.brandId,
      };
      console.log(setData);
      const php = "number_call/setAchievement";
      const results = await this.doAxios({ php, setData });

      console.log(results);

      this.$store.dispatch("authIsProgress", false);
    },
    priceFormat(price) {
      const formatter = new Intl.NumberFormat();

      if (price == null || price == "" || price == 0) {
        return 0;
      } else {
        price = Math.round(price);
        return formatter.format(price);
      }
    },
    //画像関連
    async setInputImage() {
      this.$store.dispatch("authIsProgress", true);
      this.watch_urls = [];
      this.clearImage = false;
      this.watch_images.map((value) => this.watch_urls.push(value.url));
      const setData = {
        watch_image: this.watch_urls,
      };
      const php = "set_new_watch_image/setImages";
      const results = await this.doAxios({ php, setData });

      console.log(this.results);
      this.imgUrl = this.image_dir + results;
      this.isImg = true;
      this.imagedatas = [];
      this.get_images();
      this.watch_images = "";
      this.clearImage = true;
      this.$store.dispatch("authIsProgress", false);
      this.imageDialog = false;
    },
    async get_images() {
      this.$store.dispatch("authIsProgress", true);
      const php = "get_images/getImages";
      let setData = { id: this.repair_id, status: "1" };
      this.imagedatas.push(await this.doAxios({ php, setData }));
      setData = { id: this.repair_id, status: "4" };
      this.imagedatas.push(await this.doAxios({ php, setData }));
      this.$store.dispatch("authIsProgress", false);
    },
    imageShows() {
      this.get_images();
      if (this.isImageShows === this.repair_id) {
        this.isImageShows = false;
      } else {
        this.isImageShows = this.repair_id;
      }
    },
    zoomImage(imageFile) {
      window.open(imageFile, null, "width=500,height=500");
    },
    isDeleted(thisImage, imageID) {
      this.deleteImage = thisImage;
      this.deleteID = imageID;
      this.dialog = true;
    },
    async isDelete() {
      this.$store.dispatch("authIsProgress", true);
      const php = "set_new_watch_image/deleteImage";
      let setData = { id: this.deleteID };
      this.result = await this.doAxios({ php, setData });
      this.imagedatas = [];
      this.get_images();
      this.deleteID = "";
      this.dialog = false;
      this.$store.dispatch("authIsProgress", false);
    },
    //エミット
    getChildImages(payload) {
      console.log(payload);
      this.watch_images = payload;
    },
    getChildIsImages(payload) {
      console.log(payload);
      this.isImages = payload;
    },
    getClosed(payload) {
      this.isConditionDialog = payload;
    },
    getChildBrand(payload) {
      console.log(payload); //53 GS 59 クレドール
      var array = [
        "12",
        "32",
        "37",
        "45",
        "53",
        "49",
        "59",
        "209",
        "88",
        "94",
        "117",
        "118",
        "196",
        "197",
        "205",
      ];
      if (array.includes(payload.id)) {
        if (payload.id == "53" || payload.id == "59") {
          this.brandId = 88;
        } else {
          this.brandId = payload.id;
        }
      } else {
        this.brandId = 0;
      }
      this.isBrand = true;
    },
    getChildBrandSub(payload) {
      this.watch.brandSub = payload;
    },
  },
  watch: {},
};
</script>

  <style>
.scroll {
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>