<template>
  <div>
    <div ref="TOP"></div>

    <div class="top-box mt-10">
      <div class="slide">
        <img src="/imgs/img3.jpg" alt="" />
      </div>
      <p class="top-left">STORE<br /></p>
    </div>

    <!--大宮店-->
    <v-card width="90%" class="mx-auto" style="background-color: #eeffee">
      <v-row class="mt-8">
        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
          <v-card width="350" class="ml-2" flat>
            <img width="100%" src="/imgs/img3.jpg" />
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
          <div class="ml-5">
            <h2>
              <router-link to="/ohmiyaten" class="deco"
                >大宮店 《詳しくはこちら》</router-link
              >
            </h2>
            <br />

            <v-row>
              <div class="address-font">
                〒330-0845<br />
                埼玉県さいたま市大宮区仲町2-24-2<br />金杉仲町ビル １階<br />
                タイガー薬局様となり
              </div>
            </v-row>
            <v-row>
              <div class="time-font tel mt-3">
                <a href="tel:048-662-9300">TEL:048-662-9300</a>
              </div>
            </v-row>

            <v-row>
              <div class="time-font">
                営業時間:10:30-19:30<br />
                定休日:金曜日
              </div>
            </v-row>
            <v-row>
              <span v-if="notice" class="mr-4 mt-4 mb-3">{{
                dispNotice()
              }}</span>
            </v-row>
            <v-row>
              <v-btn
                color="green"
                dark
                small
                href="https://lin.ee/1LW6qTQ"
                target="_blank"
              >
                公式LINE
              </v-btn>
              <v-btn
                color="pink"
                dark
                small
                href="mailto:ohmiya@time-repair.com?subject=%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body= %0d%0a 
%E3%80%90%E3%81%8A%E5%90%8D%E5%89%8D%E3%80%91%EF%BC%9A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20 様 %0d%0a  %0d%0a
%E3%80%90%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%80%91%EF%BC%9A%20%0d%0a
%E3%83%A2%E3%83%87%E3%83%AB%E7%95%AA%E5%8F%B7%E7%AD%89%E3%82%8F%E3%81%8B%E3%82%8C%E3%81%B0%E8%A8%98%E8%BC%89%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84(%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20)  %0d%0a %0d%0a
%E3%80%90%E7%97%87%E7%8A%B6%E3%80%91%EF%BC%9A%20 %0d%0a  %0d%0a 
%E2%80%BB%E5%8F%AF%E8%83%BD%E3%81%A7%E3%81%82%E3%82%8C%E3%81%B0%E3%81%8A%E6%99%82%E8%A8%88%E3%81%AE%E5%86%99%E7%9C%9F%E3%82%92%E6%B7%BB%E4%BB%98%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82"
              >
                メール
              </v-btn>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
          <iframe
            class="ml-5"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d807.9179627216496!2d139.62764732917674!3d35.90605178502673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1effd42cfc9%3A0x42066cece9995253!2zVGltZVJlcGFpci3jgr_jgqTjg6Djg6rjg5rjgqIt!5e0!3m2!1sja!2sjp!4v1678259509926!5m2!1sja!2sjp"
            width="350"
            height="350"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </v-col>
      </v-row>
    </v-card>
    <!--神田店-->
    <v-card width="90%" class="mx-auto" style="background-color: #eeffee">
      <v-row class="mt-8">
        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
          <v-card width="350" class="ml-2" flat>
            <img width="100%" src="/imgs/img6.jpg" />
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
          <div class="ml-5">
            <h2>神田店</h2>
            <br />

            <v-row>
              <div class="address-font">
                神田店は技術者が常駐していないため、<br />
                受付のみとなります。<br />
                担当者不在の時間がございます。<br />
                一度お電話にて確認をお願いします。<br />
              </div>
            </v-row>
            <v-row>
              <div class="time-font tel mt-3">
                <a href="tel:070-7102-8107">TEL:080-7102-8107</a>
              </div>
            </v-row>

            <v-row>
              <div class="time-font">
                営業時間:10:00-16:30<br />
                定休日:土・日・祝
              </div>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12959.58069880245!2d139.7716251!3d35.7041971!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d0612398e33%3A0x779cba88136db6d9!2z5qCq5byP5Lya56S-6ZW36YeO5ZWG5bqX!5e0!3m2!1sja!2sjp!4v1678264776845!5m2!1sja!2sjp"
            width="350"
            height="350"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return { notice: [] };
  },
  mixins: [doAxios],

  methods: {
    async getNotice() {
      const php = "number_call/getNotice";
      this.notice = await this.doAxios({ php });
      console.log(this.notice);
    },
    dispNotice() {
      if (this.notice == null || this.notice == "") {
        return;
      } else {
        const res = this.notice.filter((value) => value.notice_type == 1);
        if (res == null || res == "") {
          return;
        } else {
          return res[0].notice;
        }
      }
    },
  },
  mounted() {
    this.getNotice();
    if (this.$route.hash.match(/^#/)) {
      const el = this.$refs[this.$route.hash.slice(1)];
      el.scrollIntoView({ behavior: "smooth" });
    } else {
      const el = this.$refs["TOP"];
      el.scrollIntoView({ behavior: "smooth" });
    }
  },
  watch: {},
};
</script>
<style scoped>
.bk {
  background: #4f7594;
  color: #ffffff;
  width: 80%;
  text-align: center;
}
table {
  border: 3px solid #4f7594;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  width: 350px;
}
table thead th,
table tbody th,
table tbody td {
  border-bottom: 1px solid #aaa;
}
table th {
  background-color: #4f7594;
  color: #ffffff;
}
.deco {
  text-decoration: none;
  color: black;
}
.top-box {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  background-color: rgba(19, 53, 128, 0.8);
}

.top-left {
  position: absolute;
  top: 20%;
  left: 10%;
  color: white;
  font-size: 2.5rem;

  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.14em;
  transform: scaleX(0.825);
  transform-origin: left center;
  text-shadow: 4px 5px 4px rgb(28, 72, 168);
}

.slide {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  background: white;
}

.slide img {
  position: absolute;
  top: 20%;
  left: 50%;

  width: calc(100% + 100px);
  opacity: 0;
  animation: slideshow 8s both 1;
}

@keyframes slideshow {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  10% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -40%);
  }
}
</style>
