<template>
  <div id="app">
    <v-app>
      <vue-headern v-if="isHeader == 1" class="mb-5"></vue-headern>
      <vue-headers v-if="isHeader == 3"></vue-headers>

      <router-view class="mt-5" />

      <v-footer dark padless app>
        <v-card class="flex" flat tile>
          <v-card-text class="py-2 white--text text-center">
            <span class="font">2021 TimeRepair By Enhance reserved. </span>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-app>
  </div>
</template>


<script>
import VueHeadern from "./components/organisms/Header_n";

import VueHeaders from "./components/organisms/Header_s";
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),

  components: {
    VueHeadern,
    VueHeaders,
  },
  computed: {
    isHeader() {
      const login = this.$store.state.LoginToken;
      const token = this.$store.state.Token;

      if (login === "stafflogin" && token !== "") {
        return 3;
      } else if (login === "login" && token !== "") {
        return 2;
      } else if (login === "numberCall") {
        return 4;
      } else {
        return 1;
      }
    },
  },
  name: "App",
  methods: {
    createPageTitle(to) {
      // タイトルを設定
      if (to.meta.title) {
        var setTitle = to.meta.title + "";
        document.title = setTitle;
      } else {
        document.title = "時計修理専門店　タイムリペア-TimeRepair-";
      }
    },

    to_hash(path, Hash) {
      if (path == this.$route.name) {
        this.$router.push({ hash: "" });
        this.$router.push({ hash: Hash });
      } else {
        this.$router.push({ name: path, hash: Hash });
      }
    },
  },

  mounted: function () {
    var to = this.$route;
    this.createPageTitle(to);
  },
  watch: {
    $route(to) {
      this.createPageTitle(to);
    },
  },
};
</script>
<style>
#app {
  padding-left: 0px;
  padding-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgb(255, 255, 255);
  font-family: Zen Kaku Gothic New, Hiragino Sans, Hiragino Kaku Gothic ProN,
    ヒラギノ角ゴ ProN W3, 游ゴシック, YuGothic, 游ゴシック体, Noto Sans JP,
    BIZ UDPGothic, Meiryo, Helvetica Neue, Arial, sans-serif;
  font-style: normal;
  color: #333;
  -webkit-text-size-adjust: 100%;
}

.font {
  font-family: "georgiai";
}
.bc-color {
  background-color: #ffffff;
}
.sub-color-bc {
  background: #d28a94;
}
.sub-color {
  color: #d28a94;
}
</style>
