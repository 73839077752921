<template>
  <div>
    <div class="summary">
      <ul>
        <li class="ml-5">
          <router-link to="/privacy" class="deco"
            >・プライバシーポリシー</router-link
          >
        </li>
        <li class="ml-5">
          <router-link to="/terms" class="deco">・利用規約</router-link>
        </li>
        <li class="ml-5">
          <router-link to="/contract" class="deco"
            >・特定商取引法に基づく表示</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return {};
  },
  mixins: [doAxios],
  components: {},
  methods: {},
  mounted() {
    this.$nextTick(function () {});
  },
  watch: {},
};
</script>
<style scoped>
li {
  display: inline;
}
.deco {
  text-decoration: none;
  color: rgb(32, 32, 56);
}
.deco:visited {
  color: rgb(32, 32, 56);
}
</style>
