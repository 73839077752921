<template>
      <div>
          <v-row no-gutters v-if='isSelect'>
            ブランド名イニシャル
              <v-btn
               x-small
               color="primary"
               @click = change_intial()
               >英↔カナ</v-btn>
            
              <v-chip-group v-model="activeTab" active-class="primary--text" column color='primary' mandatory>
              <v-chip
                small
                v-for="(intial) in intials"
                :key="intial"
                @click = get_brand(intial)
                >
 
                 <span v-bind:class={customloader:isActive}>{{ intial }}</span>
              </v-chip>
            </v-chip-group>
               <v-btn
               x-small
               color="primary"
               @click ="isSelect=!isSelect;btnClick()"
               >ブランド名不明またはリストにない
               </v-btn> 
          </v-row>
                <v-btn
                v-if='!isSelect'
                x-small
               color="primary"
               @click ="isSelect=!isSelect;btnClick()"
               >ブランドリスト
               </v-btn>  
                <v-row no-gutters> <v-btn color="red" dark text x-small>必須</v-btn></v-row>
            <v-row no-gutters>
              <v-col class="d-flex" cols="11">
                <v-select
                v-if="isSelect"
                prepend-icon="mdi-watch"
                item-value="id"
                item-text= "brandname"
                :items="watch"
                label="ブランド名"
                v-model="selectWatch"
                return-object
                @change="change_maker"                
                required
                :rules="passRules"

                >
             
                </v-select> 
                <v-text-field
                v-if="!isSelect"
                prepend-icon="mdi-account-circle" 
                v-model="setBrandName"
                label="ブランド名を入力してください"
                @change="change_brand_name"
                required
                :rules="passRules"
                >
                <template v-slot:append-outer>
                  <v-btn color="red" dark text x-small>必須</v-btn>
                </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isShow" no-gutters> 
              <v-col class="d-flex" cols="11">
                <v-select
                prepend-icon="mdi-account-circle"
                item-text= "subname"
                item-value="id"
                :items="watchSub"
                label="モデル名"
                v-model="selectWatchSub"
                return-object
                @change="change_maker_sub"
                ></v-select> 
              </v-col>
            </v-row>
        </div>
</template>
<script>

import doAxios from '@/components/modules/AxiosPost'
  export default {
    data () {
      
      return {
        isSelect:true,
        setBrandName:"",
        e1: 1,
        user:[],
        results:{},
        results_sub:[], 
        watch:[],
        watchSub:[],
        selectWatch:"",
        selectWatchSub:"",
        isShow:false,
        val:[],
        intialsJp:['ア','カ','サ','タ','ナ','ハ','マ','ヤ','ラ','ワ'],
        intialsEn: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        intials:[],
        intialChange:'ア',
        activeTab:0,
        isActive:false,
        brandName:'nameJp',
        watch_id_val:"",
        passRules: [
        v => !!v || '必須項目です。',
        ],  
      }      
    },

    
  mixins: [doAxios],

   methods: {
   async get_brand(intialThis){

        this.$store.dispatch('authIsProgress', true)  
        const setData = {"intial" : intialThis};

        this.watch = []
        this.watchSub = []
        this.selectWatch=[]
        this.selectWatchSub=[] 

  
        if(intialThis.match(/^[A-Za-z0-9]*$/)){
        const php = 'get_brand_name/brandNameEn';
        this.results = await this.doAxios({php,setData});
        await this.results.map(key =>
        this.watch.push ({ id: key.id, brandname: key.brand_name_en }))
        }else{
        const php = 'get_brand_name/brandNameJp';
        this.results = await this.doAxios({php,setData});
        await this.results.map(key =>
        this.watch.push ({ id: key.id, brandname: key.brand_name_jp }))
        }

        this.isShow = false
        this.$store.dispatch('authIsProgress', false)  
        this.get_brand_sub()
    },
       async get_brand_sub(){
        this.$store.dispatch('authIsProgress', true)  ;
        const setData = {"brand_id" : this.selectWatch.id};
        const php = 'get_brand_name/brandNameSub';
        this.watchSub = []
        this.results_sub = await this.doAxios({php,setData});
        
        if(this.results_sub[0] != null){
        if(this.intialChange.match(/^[A-Za-z0-9]*$/)){
          this.results_sub.map(key =>
          this.watchSub.push ({ id: key.id, subname: key.name_en }))
        }else{
          this.results_sub.map(key =>
          this.watchSub.push ({ id: key.id, subname: key.name_jp }))
        }
       }
        if(this.watchSub =="" || this.watchSub ==null ){
         this.isShow = false
        }else{
        this.isShow = true  
        }
        this.$store.dispatch('authIsProgress', false)  
    },
    btnClick(){
            this.selectWatch =""
            this.setBrandName=""
    },
   change_intial(){
      if(this.intials == this.intialsJp){
        this.intialChange ='A'  
        this.intials= this.intialsEn
      }else{
        this.intialChange ='ア'
        this.intials = this.intialsJp
      }
        this.watch = []
        this.watchSub = []
        this.activeTab=0
          
        this.get_brand(this.intialChange)

    },
    change_brand_name(){
      this.selectWatch =""
      this.selectWatch=({id:"0",brandname:this.setBrandName})
      this.$emit("get-brand",this.selectWatch );
   },
    async change_maker(){
       await this.get_brand_sub()
       this.$emit("get-brand",this.selectWatch );
   },
    change_maker_sub(){
       this.$emit("get-brand-sub", this.selectWatchSub);
   }
  },
    mounted(){
        this.$nextTick(function(){
         this.change_intial()
        })
    }

}
</script>
<style scoped>
.customloader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>