<template>
  <div>
    <v-layout>
      <v-footer dark padless app height="110">
        <v-row justify="center" class="mb-3">
          <div>
            <h5>TimeRepair オンライン 配送無料</h5>

            <v-card-actions>
              <v-btn
                color="green"
                dark
                small
                href="https://lin.ee/1LW6qTQ"
                target="_blank"
              >
                LINEで相談
              </v-btn>
              <v-btn color="primary" small dark href="tel:048-662-9300">
                電話で相談
              </v-btn>
              <v-btn
                color="pink"
                small
                dark
                href="mailto:online@time-repair.com?subject=%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body= %0d%0a 
%E3%80%90%E3%81%8A%E5%90%8D%E5%89%8D%E3%80%91%EF%BC%9A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20 様 %0d%0a  %0d%0a
%E3%80%90%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%80%91%EF%BC%9A%20%0d%0a
%E3%83%A2%E3%83%87%E3%83%AB%E7%95%AA%E5%8F%B7%E7%AD%89%E3%82%8F%E3%81%8B%E3%82%8C%E3%81%B0%E8%A8%98%E8%BC%89%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84(%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20  %20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20)  %0d%0a %0d%0a
%E3%80%90%E7%97%87%E7%8A%B6%E3%80%91%EF%BC%9A%20 %0d%0a  %0d%0a 
%E2%80%BB%E5%8F%AF%E8%83%BD%E3%81%A7%E3%81%82%E3%82%8C%E3%81%B0%E3%81%8A%E6%99%82%E8%A8%88%E3%81%AE%E5%86%99%E7%9C%9F%E3%82%92%E6%B7%BB%E4%BB%98%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82"
              >
                メールで相談
              </v-btn>
            </v-card-actions>
          </div>
        </v-row>
      </v-footer>
    </v-layout>
  </div>
</template>
<script>
import doAxios from "@/components/modules/AxiosPost";

export default {
  data() {
    return {};
  },
  mixins: [doAxios],
  components: {},
  methods: {},
  mounted() {
    this.$nextTick(function () {});
  },
  watch: {},
};
</script>
<style scoped>
</style>
